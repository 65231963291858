$formWidth:650px;
.loginPage{
  #backgroundImg{
    opacity: .75;
    &:after{
      @include pseudoContent;
      position:fixed;
      left: 0px;
      bottom: 0px;
      @include fillSpace;
        background: linear-gradient(to top, rgba(0,0,0,0.95) 5% , rgba(0,0,0,0) 45%);
        opacity: .7;
    }
  }
    header{
         height: 100px;

    }
    .error,.loginError{
        color: lighten($error, 20%);
        font-weight: bold;
        font-weight: 600;
        font-size: .85em;
        display: block;
        padding: 5px 5px 5px 50px;
        background: black;
        color: white;
        text-align: left;
        margin-top: -5px;
        position: relative;
        &:before{
            font-family: $iconFont;
            content:'4';
            @include absolute;
            background:lighten($error, 20%);
            color:$light;
            padding:5px 15px;
        }
        &.validation-summary-errors{
            background: transparent;
            padding:0;
            margin-top:0;
            &:before{
                display: none;
            }
            ul{
                margin: 0 0 10px 0;
                padding: 0 15px;
            }
            li{
                list-style: none;
                padding: 5px 5px 5px 45px;
                background: black;
                color: white;
                text-align: left;
                position: relative;
                overflow: hidden;
                &:before{
                    font-family: $iconFont;
                    content:'4';
                    @include absolute;
                    background:lighten($error, 20%);
                    color:$light;
                    padding:0px 10px;
                    height: 100%;
                    line-height: 1em;
                }
            }
        }
    }
   .field-validation-error, .field-validation-error.error{
    //     list-style: none;
    //     padding: 5px 5px 5px 50px;
    //     background: black;
         color: white;
    //     text-align: left;
    //     position: relative;
        font-size:.85em;
        overflow: hidden;
    //     display:block;
     margin-top: -5px;
        &:before{
          font-size: 1.3em;
          height: 100%;
          line-height: 1em;
      }
  }

    .titleArea{
      //  background: $primary;
      //  border-bottom: 8px solid $light;
        text-align: left;
        padding: 5px 15px 5px;
        margin: -25px -20px 20px -20px;
        color: $light;
        font-family: $primaryFont;
        z-index: 200;
        position: relative;
        background: $secondary;
        border-bottom: 1px solid $light;
        h1{
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1.4em;
            //color: $alt2;
        }
        .pageMsg{
            background: $primary;
            padding: 5px;
            display: inline-block;
            color: $basic;
            a{
                color: $secondary;
                text-decoration: underline !important;
                &:hover{
                    color: $light;
                }
            }
        }
        p{
            @include zeroMargPad;
            font-size: 1.2em;
            font-weight: 400;
            display: inline-block;
            max-width: 900px;
            width: 100%;
            text-align: left;
        }
    }
    #backgroundImg{
        div{display: block; &:after{display: none;}}
        // &:after{
        //     @include pseudoContent;
        //     @include transparentBG($accent, .85);
        //     @include fillSpace;
        //     @include fixed;
        // }
    }

    .error{
        color: lighten($error, 20%);
        font-weight: bold;
        font-weight: 600;
        font-size: .85em;
        display: block;
        margin-top: -1px;
    }

}
.hasErrors{
  select{
    background: lighten($error, 30%);
  }
}


#membership{
   margin-top: $headerHeight - 12px;
    .errorWrap{
      padding: 20px 25px;
      @include transparentBG($primary, .85);
        text-align: center;
        max-width: 1350px;
        margin: 0 auto;
        h1{
            color: $secondary;
            text-transform: uppercase;
            font-size:2.5em;
            font-family: $primaryFont;
            margin-bottom: 7px;
        }
        .errorMsg{
            font-size: 1.5em;
            font-weight: 700;
            @include zeroMargPad;
            color: $light;
        }
    }
}
.forgotPWPG{
  .message{display: none;}
}
.formWrap{
    max-width:$formWidth;
    width: 100%;
    margin: 0 auto;
    @include clearfix;
    position: relative;
    padding: 25px 20px;
    @include transparentBG($primary, .75);
    &.login{
        max-width: 600px;
        //  max-width: 475px;
        //background: transparent;
    }
    &.password{
      .formArea{
        padding-top: 15px;
      }
    }

    p{
        font-family: $basicFont;
        font-weight: 400;
        font-size: .8em;
        text-align: center;

    }

    button, .btn{
       @include btnStyle;
        border: 0px;
    }


}
.formArea{
    padding: 0px 0px 0px;
    max-width: 1350px;
    margin: 0 auto;
    label{
    }
    p{
        margin-bottom: 25px;
        margin-top: 0px;
        font-family: $basicFont;
        font-weight: 400;
        font-size: 1em;
        color: $light;
        a{
            font-weight: 700;
            &:hover{
                text-decoration: underline !important;
                color: $secondary;
            }
        }
    }
    h1{
        font-family: $primaryFont;
        font-size: 2.2em;
        color: $light;
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;
    }
    .fieldWrapper{
        margin-bottom: 5px;
        input{
            border: 1px solid darken($grey, 25%);
        }
    }

}

.logError{
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    background: $light;
    text-align: center;
    padding: 15px;
    h1{
        color: $primary;
    }
    .error{
        color: $basic;
        font-size: 1.2em;
    }
}

.fieldWrapper{
    @include clearfix;
    width: 50%;
    margin: 0 auto;
     margin-bottom: 5px;
     float: left;
     padding: 0px 5px;
     @include borderBox;
    &.full{
        width: 100%;
        float: none;
        @include clearfix;
    }

    label{
        color: $light;
        font-weight: 600;
        font-family: $basicFont;
        font-size: 1em;
        text-transform: uppercase;
        padding-bottom: 1px;
        margin-bottom: 3px;
        display: none;

    }
    input,select{
         margin-bottom: 5px;
        padding: 12px 15px;
        font-size: 1.3em;
        font-family: $basicFont;
        box-shadow: none;
        width: 100%;
        border: 0px;
        @include rounded(3px);



        &.input-validation-error{
            background: $light;
            border: 2px solid $error;
        }
        &:focus{
            background: lighten($grey, 45%) !important;
            outline: none;
        }
    }
}
.ie8{
    .fieldWrapper label{display: block;}
}
.infoFields{
  @include clearfix;
}
.pwWrap{
  @include clearfix;
  margin-top: 10px;
  border-top: 1px solid $alt;
  padding-top: 20px;
  .fieldWrapper{
  //  width: 75%;
    //margin: 0;
  }
}

.userAccount{
  .Submit{
  text-align: center;
  button{
  width: 48%;
}

  }
}

//Log in screen//

.loginFields{
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
    .fieldWrapper{
        float: none;
        width: 100%;
        margin: 0px;
        padding: 0px;
        position: relative;
        label{
            display: none;
        }
        input{
         margin-bottom: 0px;
            padding-left: 45px;
        }
        &:before{
           @include iconFont;
            left: 0px;
            font-size: 1.6em;
            font-weight: normal;
            color:$basic;
            @include opacity(75,.75);
            width: 40px;
            text-align: center;
            @include vertCenter;
            position: absolute;
        }

    }
    .username{
        input{
            border-radius: 5px 5px 0 0;
        }
        &:before{
            content: '3';
            text-transform: uppercase !important;
        }
    }
    .password{
        input{
            border-radius:0 0 5px 5px ;
        }
        &:before{
            content: 'c'
        }
    }
    .password{input{border-radius:  0 0 5px 5px;}}
    .Submit{
        padding-right: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        button{
            width: 100%;
            margin: 0px;
        }

    }

}
.buttonWrap{
    @include clearfix;
    width: 450px;
    padding: 0px;
    width: 100%;
    margin: 0 auto;
}
.accountBtn{
    display: inline-block;
    width: 48%;
    margin: 0px 0.5%;
    float: left;
    a{
      @include btnStyle;
        padding: 15px 20px;
        font-size: 1.5em;
       margin-top: 10px;
        position: relative;
        font-weight: 700;
       color: $primary;
        background: $light;
        display: block;
        text-align: center;
    //   border: $light 1px solid;

        &:hover{
            border-color: $alt;
            background: $alt;
            color: $secondary;
            .subText{
              color: $secondary;
            }
        }
        span{display: block; }
        .topText{
          margin-bottom: 10px;
          border-bottom: 1px solid $primary;
          padding-bottom: 8px;
        }
        .subText{
          font-size: .75em;
          line-height: 1.2em;
          color: $accent;
          text-transform: uppercase;
        }
    }
}


.newStudentWrapper{
    border-top: 1px solid $alt;
    padding-top: 25px;
    margin-top: 25px;
    h1{
        font-size: 1.9em;
        margin-bottom: 0px;
    }
    p{margin-bottom: 10px;}
}

.forgotPW{
    width: 100%;
    max-width: 575px;
    .fieldWrapper{
        width: 100%;
    }
    .message{color: $secondary;
    font-weight: bold;}
}


.Submit{
    margin: 0 auto;
    text-align: right;
    clear: both;
    width: 100%;
    margin-top: 10px;
    button{
        width: 40%;
        margin-right: 1%;
    }

}
.utility{

    .utility{
        padding-top: 10px;
    }
    .forgotPass{
        text-align: center;
    }

    a{
        color: $light;
        font-size: .9em;
        text-decoration: none !important;
        margin-right: 10px;
        font-weight: 300;
        //color: $link;
        text-align: center;
        @include opacity(75,.75);
        &:hover{
            text-decoration: underline !important;
            @include opacity(100,1);
           // color: $dark;
        }
    }
}

.memberSelect{
    width: 75%;
    float: none;
    padding: 15px;
    margin: 0px;
//    padding-bottom: 25px;
    margin-bottom: 15px !important;
    background: $primary;
    @include rounded(3px);
    font-family: $primaryFont;
    h2{
        font-size: 1.15em;
        text-transform: uppercase;
        color: $light;
        margin-bottom: 15px;
        text-align:left;
        font-family: $primaryFont;
        font-weight: 400;
    }
    .radioField{
        display: inline-block;
        margin: 0px 10px;
    }
    .catWrap{
        @include clearfix;
        text-align: center;
    }
    label{
        display: none;
        float: left;
        text-align: center;
        cursor: pointer;
    }
    .rd{
        margin-top: 4px;
        input{outline: none;border: 0px;}
    }
    &.readOnly{
      padding: 15px 15px 0px;
      input{
        background: none;
        color: $light;
        font-weight: 700;
        border: 0px;
        cursor: default;
        padding: 0px;
        &:focus{
          background: none !important;
          color: $light !important;
          cursor: default;
        }
      }
    }
}

.errorPage{
  padding: 20px;
  .formArea{
    color: $light;
    font-size: .9em;
  }
  h3{
    margin-top: 5px;
  }
}


@media (max-width: $sm){
    .loginPage{
        header{
            .branding{
                padding-top: 8px;
            }
            .mainTitle{
                right: 30px;
            }
        }
    }
    #membership{
      margin-top: 0px;
    }

}



@media (max-width: $xs){
    #membership{
      //  margin-top: 75px;
    }
    .formArea{
        h1{
            font-size: 1.8em;
        }
    }
    .forgotPW{
        padding: 20px 5px;
    }

    .buttonWrap{
      .accountBtn{
        float: none;
        display: block;
        width: 100%;
      }
    }
    .fieldWrapper{
        width: 100%;
        float: none;
    }
    .Submit{
        margin-top: 0px;
        margin-bottom: 10px;
        button{
            width: 100%;
        }
    }
    .memberSelect{
        .catWrap{
            text-align: left;
        }
        .radioField{
            display: block;
            float: none;
        }
        label{
            float: none;
            display: block;
            text-align: left;
            @include clearfix;
            position: relative;
            .txt{
                float: left;
                display: inline-block;
                padding-left: 20px;
            }
            .rd{
                position: absolute;
                left: 0px;
                top: -1px;
                height: 15px;
                width: 15px;
            }
        }
    }

}




@media (max-width: $xxs){
//    .formWrap.password .fieldWrapper{width: 100%;}
//    .loginPage{
//        .newStudentWrapper .account{margin-top: 15px;}
//        header{
//             .student{
//                display: none;
//            }
//            .topLine{
//                padding-bottom: 5px;
//            }
//            .branding{
//                width: 100%;
//                float: none;
//                text-align: center;
//                .logoWrap{
//                    margin: 0 auto;
//                    .logo{
//                        max-width: 250px;
//                    }
//                }
//            }
//        }
//    }
//    .userAccount{
//        .fieldWrapper{
//            width: 100%;
//            float: none;
//        }
//    }
//     .returningStudentWrapper {
//         padding: 15px;
//        .fieldWrapper{
//            width: 95%;
//        }
//     }
}
