$utilityWidth: 550px;
.utilityBtn {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 2;
  @include transition(left,.2s);
  /*
  &.open {
    position: fixed;
    z-index: 250;
    top: 25px;
    background: $primary;
    color: $light;
    right: $utilityWidth;
    border: 3px solid $light;
    border-right: 0;
    .icon {
      &:before {
        content: 'b';
      }
    }
  }*/
  // .inSection &{
  //   left: -200px;
  //   // .icon-menu{
  //   //   left: -150px;
  //   // }
  //   // .icon-home{
  //   //   top: 28px;
  //   // }
  // }
}
.inSection {
  .utilityBtn {
    left: -200px;
  }
}
.utilityMenu {
  position: fixed;
  left: -$utilityWidth;
  max-width: $utilityWidth;
  width: 100%;
  top: 0;
  background: $light;
  height: 100%;
  z-index: 231;
  overflow-y: scroll !important;
  /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
  @include transition();
  @include scrollbar($color: $hover);
  border-left: 60px solid $light;
  &.open {
    left: 0;
    .closeBtn {
      left: 0px;
    }
  }
  .closeBtn {
    position: fixed;
    left: -$utilityWidth;
    top: 10px;
    width: 60px;
    height: 90px;
    z-index: 99;
    font-size: 1.8em;
    color: $secondary;
    //background-color: $primary;
    border-right: 1px solid $primary;
    @include transition();
    html:not(.mobile) &{
      &:hover {
        background-color: $primary;
        color:$light;
        cursor: pointer;
      }
    }
    span {
      display: block;
      @include vertCenter;
      text-align: center;
      padding-bottom: 10px;
    }
  }
  .menuContent {
    @include clearfix;
    // margin:20px 0;
    // margin-left:40px;
    //border-bottom: 2px solid $grey;
    //padding: 20px 15px 15px;
  //  border-left: 1px solid $primary;
    h1 {
      font-weight: 700;
      text-transform: none;
      color: $primary;
      font-size: 2.4em;
    }
    &.UMinfo {
      padding: 15px;
      font-family: $primaryFont;
      //text-align: right;
      background-color: $light;
      border-bottom: 0px solid $primary;
      .UMtitle {
        font-weight: 700;
        text-transform: none;
        color: $secondary;
        font-size: 2.4em;
        text-align: center;
      }
      .UMstudent {
        color: $secondary;
        font-weight: 600;
        text-align: center;
      }
    }
    &.UMbtns {
      // margin:0px 12px 0 12px;
      padding: 5px 15px 25px;
      text-align: center;
      //background: $grey;
    }
    &.toc {
      margin-left: 0;
      padding-left: 0;
      background: $light;
      h1 {
        padding: 5px 0 5px 15px;
        font-size: 1.8em;
        background-color: $primary;
        color: $light;
      }
    }

    .btn {
      @include button;
      display: block;
      margin: 0 2px 4px;
      display: inline-block;
      font-size: 1.1em;
      text-align: center;
      cursor: pointer;
      border: 0;
      text-decoration: none !important;
    }
    .certBtn {
      // display:none;
      float: left;
      clear: both;
      margin-top: 10px;
      padding-left: 30px;
      background: $light!important;
      border-color: $primary;
      color: $primary!important;
      position: relative;
      .icon-cert {
        @include absolute;
        top: 4px;
        left: 8px;
        font-size: 1.25em;
      }
      html:not(.mobile) &{
        &:hover {
          background: $hover;
          border-color: $light;
          color: $light;
        }
      }
    }
    .orientationComplete & {
      .certBtn {
        display: inline-block;
      }
    }
  }
}
//*****TOC******//
#sitemap {
  // font-size:1.25em;
  font-family: $primaryFont;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    //padding-left: 0;
    ul {
      li {
        position: relative;
        html:not(.mobile) &{
          &:hover {
            .lockedMsg {
              display: block;
            }
            li {
              .lockedMsg {
                display: none;
              }
            }
          }
        }
        &.level-4 {
          html:not(.mobile) &{
            &:hover {
              .lockedMsg {
                display: block!important;
                z-index: 9999;
              }
            }
          }
        }
      }
      ul {
        //padding-left:40px;
      }
    }
  }
  .level-2 {
    @include zeroMargPad;
    div {
      display: none;
    }
    a {
      //text-transform:uppercase;
      font-size: 1.3em;
      color: $light;
      background: $secondary;
      padding: 5px 10px 5px 20px;
      cursor: default;
      margin-bottom: 5px;
      font-weight: 600;
    }
  }
  .level-3 {
    &.selfDir {
      a {
        // border:2px solid $grey;
        // margin-left:20px;
        font-weight: bold;
      }
    }
    a {
      text-transform: none;
      font-size: 1.1em;
      color: $primary;
      background: transparent;
      padding: 7px 10px 7px 20px;
      cursor: pointer;
      position: relative;
      //color: $primary;
      position: relative;
      text-decoration: none !important;
      //border-bottom: 1px $grey solid;
      html:not(.mobile) &{
        &:hover {
          background: $light;
          color: $secondary;
          &:before {
            font-family: $iconFont;
            content: 's';
            @include absolute;
            left: 2px;
            top: 50%;
            margin-top: -10px;
          }
        }
      }
    }
  }
  .level-4 {
    a {
      margin-left: 20px;
      padding: 10px 10px 10px 20px;
      //background:darken($light,30%);
      font-weight: 600 !important;
      font-size: 0.9em;
      border: 0;
      border-bottom: 1px solid $primary !important;
    }
  }
  a {
    text-decoration: none!important;
    display: block;
    //@include transition;
  }
  .locked {
    a {
      cursor: default;
    }
    .level-3 {
      a {
        @include opacity(50,.50);
      }
    }
  }
  .lockedMsg {
    display: none;
    @include absolute;
    width: 100%;
    top: 0;
    background: $dark;
    color: $light;
    font-weight: 700;
    padding: 10px;
    text-align: center;
    font-size: 0.9em;
    z-index: 1;
    height: 100%;
    cursor: not-allowed;
  }
}
#printOrientation {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba($basic, 0.9);
  
  .dialog {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    display: block;
    width: 280px;
    height: 180px;
    padding: 15px;
    background: 50% url(/umbraco/assets/img/loader.gif) no-repeat;
    background-position: 50% 50%;
    background-color: $light;
    border-radius: 3px;
    transition: all 500ms;

    .printProgress {
      position: absolute;
      left: 15px;
      right: 15px;
      top: 15px;
      height: 5px;
      background-color: $grey;
      text-align: center;

      .bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 100%;
        background-color: $primary;
        transition: width 200ms;
      }
      
      .num {
        position: absolute;
        top: 12px;
        left: 0;
        right: 0;
        text-align: center;
        color: $primary;
        font-family: $primaryFont;
        font-size: 0.9em;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }

  button.cancel {
    @include button($basic);
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  iframe {
    display: none;
    width: 100%;
    height: 100%;
    border: 1px solid $dark;
    border-radius: 4px;
  }
  
  .printButtonHolder {
    display: none;
    justify-content: space-between;
    margin-bottom: 20px;
    text-align: center;

    button {
      @include button;
      font-size: 1.3em;
    }
  }
}

#printOrientation.completed {
  .dialog {
    top: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 780px;
    height: 80vh;
    background: $light;

    .printProgress {
      display: none;
    }
    
    .printButtonHolder {
      display: flex;
    }
    
    iframe {
      display: block;
    }
    
    .cancel {
      display: none;
    }
  }
}
@media (max-width: $md + 100) {
  .utilityMenu {

  }
  .utilityBtn {
    &.open {
      // right: 60%;
    }
  }
}
@media (max-width: $sm) {
  #printOrientation.completed {
    .dialog {
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
}
@media (max-width: $xs) {
  #printOrientation {
    .printButtonHolder {
      button {
        font-size: 1em;
      }
    }
  }
  .utilityMenu {
    &.open {
      max-width: 700px;
    }
  }
}
