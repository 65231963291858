//.tips{
//    background: $primary;
//    padding: 10px 15px 20px;
//    font-size: .98em;
//    color: $light;
//    position: relative;
//    margin-top: 20px;
//    &.smTip{
//        display: none;
//    }
//
//}
//.tipTitle{
//    padding-bottom: 10px;
//     h5{
//        font-family: $primaryFont;
//         text-transform: uppercase;
//         font-weight: 700;
//         font-size: 1.5em;
//
//     }
//}
//.tip{
//    p{
//        margin-bottom: 0px !important;
//    }
//}


.tips{
    padding:15px;
    color:$light;
    position:relative;
    margin-top: 20px;
    @include transparentBG($alt, .85);
    overflow:hidden;
    &:before{
      @include pseudoContent;
      width: 300px;
      height: 500px;
      opacity: .95;
      background: url('../images/tip-mascot.png') no-repeat ;
      background-size: 275px auto;
      position: absolute;
      z-index: -1;
      right: -75px;
      top: -60px;

    }

        &.smTip{
        display: none;
    }
    h5{
        margin-top: 0!important;
        color: $secondary;
        padding: 8px 10px;
        font-weight: 400;
        font-size: 1.5em!important;
        @include clearfix;
        text-transform: uppercase;
        span{
          font-weight: 700;
        }
    }
    .icon{
        position: absolute;
        top: -15px;
        left: -15px;
        border-radius: 50%;
        font-size:1.8em;
        padding:10px;
        background:$primary;
        color:$light;
        height: 45px;
        width: 45px;
        text-align: center;
    }
    .tip{
        padding: 0 10px;
        color:$basic;
        font-weight: 600;
        line-height: 1.45em;
    }
}

@media (max-width: $md){
    .tips{
        display: none;
         &.smTip{
            display: block;
        }

    }
}
