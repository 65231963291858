footer {
  height: $footerHeight;
  position: fixed;
  bottom: 0;
  left: 0;
  background: $light;
  z-index: 5;
  width: 100%;
  color: $secondary;
  @include clearfix;
  padding: 35px 35px 0;
  animation: footerIn 1.25s;
  h1 {
    font-weight: 300;
    float: left;
    display: inline-block;
    font-size: 2.4em;
  }
  h2 {
    font-weight: 600;
    float: right;
    display: inline-block;
    padding-top: 15px;
  //  margin-right: 80px;
  margin-right: 20px;
    font-size: 1.1em;
    font-family: $primaryFont;
  }
}
.inSection {
  footer {
    bottom: - $footerHeight;
  }
}

@media (max-width: $sm){
  footer{
    padding: 35px 20px 0px;
    h2{
      display: none;
    }
  }
}

@media (max-width: $xs){
  footer{
    height: 75px;
    h1{
      display: none !important;
    }
  }
}
