header{
  height: $headerHeight;
  background: $light;
  @include fixed;
  z-index: 10;
  padding-top: 15px;
  width: 100%;
  @include transition(.5s);
  animation: headerIn 1.25s;

  .logoWrap{
    max-width: 350px;
    width: 100%;
    margin:0 auto;
    @include transition(.5s);
    padding: 0px 15px;
    img{
      width: 100%;
    }
  }
.inSection &{
  height: $smHeader;
  .logoWrap{
    max-width: 250px;
    cursor: pointer;
  }
  .headBtnHolder{
    height: 60px;
    .inner{
      padding-top: 15px;
      @include transition(.4s);
    }
  }
}

}

.headBtnHolder{
  position: absolute;
  top: 10px;
  left: 0px;
  cursor: pointer;
  color: $secondary;
  width: 80px;
  height: 90px;
  text-align: center;
 @include transition(.4s);
 .inner{
   padding-top: 28px;
 }
  &:after{
    @include pseudoContent;
    width: 1px;
    background: $primary;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    @include transition(width,.2s);
    z-index: 1;
  }
  //@media (min-width: $sm + 1){
    &:hover{

      .icon{
        color:$light;
      }
      &:after{
        width: 100%;
      }
    }
    .icon{
        font-size: 2.3em;
        display: inline-block;
        line-height: 1em;
         @include transition(color,.1s);
        @include horzCenter;
        position: absolute;
        z-index: 2;
    }

}

.menuBtn{
  position: absolute;
  @include fillSpace;
  top: -200px;
  @include transition(top,.2s);
  z-index: 2;
  .in2 &{
    top: 0px;
  }
}

@media (max-width: $lg + 40px){
  header{
    .searchMode &{
      padding-right: 300px;
    }
  }
}

@media (max-width: $md){
  header{
    .logoWrap{
      max-width: 325px;
    }
  }

}

@media (max-width: $xs){
  header{
    height: 100px;
    padding-top: 10px;
    .logoWrap{
      max-width: 280px;
    }
    .inSection &{
      height: 70px;
      .logoWrap{
        max-width: 240px;
      }
      .headBtnHolder{
        height: 50px;
        .inner{
          padding: 10px;
        }
      }
    }
    .in2 &{
      position: static;
    }
  }
  .headBtnHolder{
    height: 60px;
    width: 60px;
    .inner{
      padding-top: 17px;
    }
  }

}

@media (max-width: $xxs){
  header{
    padding-left: 60px;
    .logoWrap{
      max-width: 270px;
    //  padding: 0px;

    }
  }
}

@media (max-width: $xxs - 50){
  header{
    .logoWrap{
      // float: right;
      // max-width: 230px;
      // margin-left: 70px;
      // padding: 0px;
      // padding-right: 10px;
    }
  }
}
