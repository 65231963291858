.loginPage{
	.errorPage{
		background: rgba($light, .75);
		max-width: 100%;
		margin-top: -20px;
		padding: 30px 10px 50px;
		height: 100%;
		text-align: center;
		.formArea{
			color: $primary;
			max-width: 650px;
			margin: auto;
		}
		h1{
			color: $alt;
		}
		h2{
			font-size: 2em;
		}
	}
}
