.masterQuizSlide,
.surveySlide {
  #learnMoreWrapper {
    display: none !important;
    opacity: 0 !important;
  }
  .copy{
   max-width: 960px;
   width: 100%;
   margin: 0 auto;
  }

  h3 {
    display: block;
  }
  form{
    @include clearfix;
    margin-top: 35px;
    padding-bottom: 90px;
    position: relative;
  }
  .slideCopy {
    padding-right: 0px;
    padding-bottom: 90px;
    margin: 0 auto;
  //  max-width: 750px;
    width: 100%;
    float: none;
  }
  .textHolder{
    width: 500px;
    float: left;
    background: $primary;
    color: $light;
    padding: 15px 25px;
    position: relative;
    z-index: 1;
  //  margin-top: 8px;
  &.notPassed{
    background: $secondary;
    &:before{
      border-color: $secondary;
    }
    h3{
      background: $alt;
    }
  }
    &:before{
      @include pseudoContent;
      height: calc(100% + 16px);
      width: calc(100% + 16px);
      border: 2px solid $primary;
      position: absolute;
      top: -8px;
      left: -8px;
      z-index: 0;
    }
    .inner{
      position: relative;
      z-index: 2;
    }
    p{
      color: $light;
      font-size: 1.1em;
      line-height: 1.4em;
    }
    h2{
      color: $light;
      padding-top: 0px;
      margin-top: 0px;
      font-size: 2.2em;
      font-family: $primaryFont;
      font-weight: 400;
    }
    h3{
      background: $accent;
      display: inline-block;
      color: $light;
      font-size: 1.3em;
      margin-bottom: 5px;
      padding: 0px 5px;
    }
  }
  .mainQuestionHolder{
    width: calc(100% - 550px);
    float: right;
  //  border: 2px solid
  }

  .questionBlock{
		@include clearfix;
		border-bottom:1px solid $grey;
        margin: 5px auto 0px;
        padding:  20px 0px 0px;
		@include transition($speed:.4s);

        &:first-child{
            margin-top: 0px;
            padding-top: 0px;
        }
        &.error{
          .num{
            //background: $wrong;
            color: $wrong;
          }
          .ques{
            color: $wrong;
          }
        }
	}
	.question{
        margin-bottom: 10px;

			padding:0px;
            padding-top: 0px;
			margin:0;
			border:0!important;
			line-height: 1.4em;
    //  padding-left: 40px;
            font-size: 1.3em;
            position: relative;
        //    font-family: $basicFont;
        //    color:$basic;
            text-transform: none;
            font-weight: 600;

@include legendFix();
            .num{
              //border: 2px solid $accent;
                color:$secondary;
                // font-weight: 700;
                // font-family: $pimaryFont;
                // display: inline-block;
                // border: 2px solid $secondary;
                // padding: 3px;
                // display: block;
                // // width: 30px;
                // // height: 30px;
                // text-align: left;
                // text-transform: uppercase;
                // font-family: $primaryFont;
                // font-weight: 400;
                // font-size:1em;
            }

        .ques{
        //  display: block;
          color:$secondary;
            p{
            display: inline;
            }
        }
	}


  //*********COMMENTBOXES*********/
  #dc0 {
    background: $primary;
    padding: 10px;
    color: $light;
    @include transition($speed:.4s);
    display: block!important;
    @include opacity(100,1);
    visibility: visible !important;

    &.hidden {
      @include opacity(0,0);
      visibility: visible !important;
      display: block!important;
      height: 0;
      overflow: hidden;
      padding: 0;

      textarea {
        height: 0;
      }
    }

    textarea {
      width: 100%;
      height: 100px;
      color: $basic;
    }
  }

  #dc1 {
    background: $primary;
    padding: 10px;
    color: $light;
    @include transition($speed:.4s);
    display: block!important;
    @include opacity(100,1);
    visibility: visible !important;

    &.hidden {
      @include opacity(0,0);
      visibility: visible !important;
      display: block!important;
      height: 0;
      overflow: hidden;
      padding: 0;

      textarea {
        height: 0;
      }
    }

    textarea {
      width: 100%;
      height: 100px;
      color: $basic;
    }
  }

  #dc2 {
    background: $primary;
    padding: 10px;
    color: $light;
    @include transition($speed:.4s);
    display: block!important;
    @include opacity(100,1);
    visibility: visible !important;

    &.hidden {
      @include opacity(0,0);
      visibility: visible !important;
      display: block!important;
      height: 0;
      overflow: hidden;
      padding: 0;

      textarea {
        height: 0;
      }
    }

    textarea {
      width: 100%;
      height: 100px;
      color: $basic;
    }
  }

  #dc3 {
    background: $primary;
    padding: 10px;
    color: $light;
    @include transition($speed:.4s);
    display: block!important;
    @include opacity(100,1);
    visibility: visible !important;

    &.hidden {
      @include opacity(0,0);
      visibility: visible !important;
      display: block!important;
      height: 0;
      overflow: hidden;
      padding: 0;

      textarea {
        height: 0;
      }
    }

    textarea {
      width: 100%;
      height: 100px;
      color: $basic;
    }
  }

  #dc4 {
    background: $primary;
    padding: 10px;
    color: $light;
    @include transition($speed:.4s);
    display: block!important;
    @include opacity(100,1);
    visibility: visible !important;

    &.hidden {
      @include opacity(0,0);
      visibility: visible !important;
      display: block!important;
      height: 0;
      overflow: hidden;
      padding: 0;

      textarea {
        height: 0;
      }
    }

    textarea {
      width: 100%;
      height: 100px;
      color: $basic;
    }
  }

  #dc5 {
    background: $primary;
    padding: 10px;
    color: $light;
    @include transition($speed:.4s);
    display: block!important;
    @include opacity(100,1);
    visibility: visible !important;

    &.hidden {
      @include opacity(0,0);
      visibility: visible !important;
      display: block!important;
      height: 0;
      overflow: hidden;
      padding: 0;

      textarea {
        height: 0;
      }
    }

    textarea {
      width: 100%;
      height: 100px;
      color: $basic;
    }
  }

  #dc6 {
    background: $primary;
    padding: 10px;
    color: $light;
    @include transition($speed:.4s);
    display: block!important;
    @include opacity(100,1);
    visibility: visible !important;

    &.hidden {
      @include opacity(0,0);
      visibility: visible !important;
      display: block!important;
      height: 0;
      overflow: hidden;
      padding: 0;

      textarea {
        height: 0;
      }
    }

    textarea {
      width: 100%;
      height: 100px;
      color: $basic;
    }
  }
  #dc7 {
    background: $primary;
    padding: 10px;
    color: $light;
    @include transition($speed:.4s);
    display: block!important;
    @include opacity(100,1);
    visibility: visible !important;

    &.hidden {
      @include opacity(0,0);
      visibility: visible !important;
      display: block!important;
      height: 0;
      overflow: hidden;
      padding: 0;

      textarea {
        height: 0;
      }
    }

    textarea {
      width: 100%;
      height: 100px;
      color: $basic;
    }
  }

  #dc8 {
    background: $primary;
    padding: 10px;
    color: $light;
    @include transition($speed:.4s);
    display: block!important;
    @include opacity(100,1);
    visibility: visible !important;

    &.hidden {
      @include opacity(0,0);
      visibility: visible !important;
      display: block!important;
      height: 0;
      overflow: hidden;
      padding: 0;

      textarea {
        height: 0;
      }
    }

    textarea {
      width: 100%;
      height: 100px;
      color: $basic;
    }
  }


  #dc9 {
    background: $primary;
    padding: 10px;
    color: $light;
    @include transition($speed:.4s);
    display: block!important;
    @include opacity(100,1);
    visibility: visible !important;

    &.hidden {
      @include opacity(0,0);
      visibility: visible !important;
      display: block!important;
      height: 0;
      overflow: hidden;
      padding: 0;

      textarea {
        height: 0;
      }
    }

    textarea {
      width: 100%;
      height: 100px;
      color: $basic;
    }
  }

  #dc10 {
    background: $primary;
    padding: 10px;
    color: $light;
    @include transition($speed:.4s);
    display: block!important;
    @include opacity(100,1);
    visibility: visible !important;

    &.hidden {
      @include opacity(0,0);
      visibility: visible !important;
      display: block!important;
      height: 0;
      overflow: hidden;
      padding: 0;

      textarea {
        height: 0;
      }
    }

    textarea {
      width: 100%;
      height: 100px;
      color: $basic;
    }
  }



  .controls{
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  #quiz-submit,
  #survey-submit.btn {
    margin: 10px auto 15px;
    text-align: center;
  //  @include rounded(0);
    @include btnStyle;
  //  border: 0px;
    display: block;
    //padding: 15px 45px;
    color: $light;
    font-size: 1.5em;
    font-weight: 700;
    outline: none;


    &:hover {
      // background: $primary
    }
  }
}

.cm-s-neat {
//   //text-align: center;
//   font-size: 1.15em;
//   //background: $secondary;
//   color: $basic;
// //  @include rounded(15px);
// //  padding: 10px;
//   margin-bottom: 40px;

  // border: 3px solid $primary;
  //   padding: 15px ;
  //   text-align: center;
  //   position: relative;
  //   z-index: 3;

  p {
    // margin: 0 !important;
    // font-weight: 700;
  }
}

.surveySlide {
  .questionBlock {
    .answer {
      div {
        color: $light!important;
      }
    }
  }

  .surveyComplete {
    // text-align: center;
    // margin-top: 20px;
    // font-size: 1.2em;
    color: $primary;
    border: 3px solid $primary;
      padding: 15px ;
      text-align: center;
      position: relative;
      z-index: 3;
      p{
        @include zeroMargPad;
        font-size: 1.3em;
        font-weight: 600;
      }
  }
}

.reveiwText{display: none;}
.masterQuizSlide {
  &.completed{
    .textHolder{
      width: 100%;
      float: none;
      text-align: center;
      margin-bottom: 35px;
    }
    .mainQuestionHolder{
      width: 100%;
      float: none;
    //  display: none;
    }
  }
  &.justGraded {

    .gradedMessages {
      display: block;
    }

    .retakeMessage {
      display: none;
    }

    .backToMenuBtn {
      cursor: pointer;
      @include btnStyle;
      @include transition;
      font-size: 1.3em;
      &:hover {
        // background: $secondary;
        // color: $alt;
      }
    }

    .questionBlock {
      display: none!important;

      &.review {
        display: block!important;
        .question{
          color: $wrong;
          .num{
            display: none;
          }
        }
        // &:last-of-type{
        //   display: none !important;
        // }
      }

      input {
        display: none;
      }

      .wrong {
        cursor: default;
        //color: $primary!important;
        //background: $wrong;
        &:before {
          @include iconFont;
          content: "b";
          background: $wrong;
          float: left;
          margin: -3px 0 0 -10px;
          font-size: 1.3em;
          color: $light;
          padding: 5px;
          @include rounded(50%);
        }

        span {
          //  background: $wrong;
          //color: $wrong;
          font-weight: bold;
        }

        &:hover {
          color: $primary!important;
        }
      }
      fieldset{
        &:hover{
          cursor: default !important;
        }
      }
      .answer {
        cursor: default !important;
        .checkbox {
          cursor: default !important;

          &:hover {
            background: transparent!important;
            cursor: default !important;
            color: $basic !important;
            input{
              cursor: default !important;
            }
            label {
              background: transparent!important;
              cursor: default !important;
              color: $basic !important;
            }

            span {
              background: transparent!important;
              cursor: default !important;
              color: $basic !important;
            }
          }

          span {
            margin-top: 0;
          }
        }
      }
    }

    #quiz-submit,
    #survey-submit.btn {
      display: none;
    }
  }

  &.completed {
    .questionBlock,
    .reviewText {
      display: none;
    }

    .gradedMessages {
      display: block;
    }
  }

  .gradedMessages {
    //display:none;
  //  background: $primary;
  // border: 3px solid $primary;
  //   padding: 15px ;
  //   text-align: center;
  //   position: relative;
  //   z-index: 3;

    h2 {
      // color: $accent;
      // font-size: 2.2em;
      // text-transform: uppercase;
      // font-family: $primaryFont;
      // font-weight: 700;
      // margin-bottom: 10px;
    }

    h3 {
      // display: inline-block;
      // color: $light;
      // font-weight: bold;
      // background: $alt;
      // padding: 5px 10px;
      // margin-top: 0px;
      // font-size: 1.5em;
      // font-family: $secondaryFont;
      // text-transform: uppercase;
      // margin-bottom: 20px;
    }

    p {
      // color: $basic;
      // padding-top: 5px;
    }
    strong{
      // color:$basic;
    }

    .backToMenuBtn {}
  }

  .Answer,
  .answer {
    padding: 0 15px;
    margin-left: 0px;
    position: relative;
    z-index: 3;

    div {
      float: none;
      width: 100%;
      text-align: left;
      margin-bottom: 5px;
      @include rounded(5px);

      label {
        display: block;
        position: relative;
        line-height: 1.4em;
        font-size: 1.1em;
        @include rounded(5px);

        &:hover {
          background: $hover;
          color: $light;
          span{
            color: $light;
          }
        }
      }

      span {
        font-size: 0.95em;
        display: block;
        padding: 3px 5px 3px 29px;
        position: relative;
        z-index: 1;
        cursor: pointer;
        color: $basic;
      }

      input {
        top: 7px;
        position: absolute;
        left: 5px;
      }

      &.selected {
        background: $secondary;

        label {
          // &:hover {
          //   span{
          //   color: $primary;
          // }
          // }
        }
        span {
          color: $light;
          font-weight: bold;
        }
      }
    }
  }
}

.ie8 {}

@media (max-width: $xl) {
  .masterQuizSlide,
  .surveySlide {
    .textHolder{
      width: 450px;
    }
    .mainQuestionHolder{
      width: calc(100% - 475px);
    }
  }
}

@media (max-width: $lg) {
  .masterQuizSlide,
  .surveySlide {
    .textHolder{
      width: 425px;
    }
    .mainQuestionHolder{
      width: calc(100% - 450px);
    }
  }
}

@media (max-width: $md) {
  .masterQuizSlide,
  .surveySlide {
    form{
      margin-top: 10px;
    }
    .textHolder{
      width: 100%;
      float: none;
      h2{
        text-align: center;
      }
    }
    .mainQuestionHolder{
      width: 100%;
      float: none;
      margin-top: 30px;
    }
    .controls{
      position: static;
    }
  }
}


@media (max-width: $xs) {
  .masterQuizSlide,
  .surveySlide {
    .textHolder{
      font-size: .9em;
    padding: 10px 20px;
      h2{

      }
    }
    fieldset{
      padding: 0px;
      margin: 0px;
    }
    // .initialInstruction {
    //   font-size: 1em;
    //   line-height: 1.15em;
    // }

    .questionBlock {
      padding: 0;

      .question {
        font-size: 1.1em;
      }
    }

    .Answer,
    .answer {
      padding: 0;
      div{
        span{
          font-size: .9em;
        }
      }
    }

    // .questionFieldset {
    //   padding: 0;
    //   margin: 0px;
    // }
  }

  .masterQuizSlide.graded .questionBlock .wrong span {
    background: transparent;
    color: $wrong;
  }
}
