#homeScreen{
    width:100%;
    height:100%;
    position: fixed;
    top:$headerHeight;
    left:0;
    overflow:hidden;
    @include transition(.5s);
    &:after{
      @include pseudoContent;
      position: fixed;
      top: 0px;
      left: 0px;
      @include transparentBG($primary,.8);
      @include transition(.5s);
      width: 0px;
    }
}

.bgLine{
  width: 100%;
  height: 1px;
  position: fixed;
  background:$primary;
  z-index: 15;

  &.top{
    top: $headerHeight - 25px;
    left: 0px;
  }
  &.topBG{
    @include transition($speed: .75s);
    .inSection & {
      top: -30px;
    }

  }
  &.bottomBG{
    bottom: $footerHeight - 25px;
    @include transition($speed: .75s);
    right: 0px;
    .inSection & {
      bottom: -30px;
    }

  }
  &.searchBG{
      left: auto;
      right: 0px;
      height: 1px;
      @include transition(height, .4s);
      .searchMode &{
        height: 100%;
        @include searchBarW;
      }
      .inSection & {
        display: none;
      }
  }

}

@media (max-width: $sm){
  #homeScreen{
    position: static;
    .in2 &{
      display: none;
    }
  }

  .alertMode{
    .bgLine.bottomBG{
      z-index: 300;
    }
  }
}

@media (max-width: $xs){
  #homeScreen{

  }
  .bgLine.top{
    top: 80px;
  }
}
