.accordionButtonWrapper{
    border-left: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 0px !important;
    padding-left: 0px !important;
       @include zeroMargPad;
        &:first-child{
            margin-top: 20px;
        }
        &.open{
            .accordionButton{
               // background: $secondary;
                cursor: pointer;
                color: $light;
                background: $accent;
                &:after{
                    text-transform: uppercase;
                    content: 'b';
                    color: $light;
                    font-size: .9em;


                }
            }
        }
    p{
        border-left: 0px !important;
        padding-left: 0px !important;
    }
    }

    .accordionButton{
         font-size: 1.3em;
        font-family: $primaryFont;
        background:$link;
        font-weight: 700;
        outline: none !important;
        color: $light;
        padding: 10px 0 12px 50px;
        display: block;
        position: relative;
        margin-bottom: 0px;
        border: 3px solid $light;
        span{position: relative; z-index: 2;display: inline-block;}
        &:hover{
            cursor: pointer;
          //  background: $hover;
            color: $light;
            &:after{
                color: $light;
            }
            &:before{
              width: 100%;
            }

        }
        &:after{
            @include iconFont;
            // position: absolute;
            // left: 13px;
            // top: 15px;
            content: 'm';
            font-size: .9em;
          //  padding:3px 10px 3px 0px;
            background: $secondary;
            height: 30px;
            width: 30px;
            padding-top: 3px;
            font-size: 1em;
            text-align: center;
            @include rounded(50%);
            color:$light;
         border: 2px solid $light;
         @include vertCenter;
         position: absolute;
         left: 10px;
         z-index: 2;
        }
        &:before{
          @include pseudoContent;
          width: 0px;
          height: 100%;
          right: 0px;
          top: 0px;
          background: $hover;
          position: absolute;
          z-index: 1;
          @include transition(.3s);

       }

    }
    .accordionWrapper{
        display: none;
        padding: 13px 20px 25px;
        margin-bottom: 3px;
        background: $light;
        p{
            border-left: 0px !important;
            padding-left: 0px !important;
        }
        h3{
            font-size: 1.15em;
            margin-bottom: 10px;
        }
    }


@media (max-width: $xs){
    .accordionButton{
        font-size: 1.15em;
        &:after{
          padding-top: 4px;
        }
    }
    // .accordionButtonWrapper{
    //     margin: 0px -20px 0px -20px !important;
    // }
    // .accordionWrapper{
    //     margin: 0px -20px 3px -20px !important;
    // }
}
