#main-nav{
  @include reformatList;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: calc(100% - 245px);
  border: 5px solid $light;
    @include transition(.5s);
  //  transition: height .5s;


&.linkHide{
  .button{
    &.clicked{
      a{display: none;}
    }
  // a{display: none;}
  }
}
  .button{
    display: inline-block;
    margin:5px;
    flex-grow: 1;
    height:50%;
    position: relative;
    width: 20%;
    overflow: hidden;
    @include useGPU;
    transform-origin: center center;
  //  @include transition(.5s);
   transition: margin .25s, height .5s;
    a{
      @include absolute;
      display: block;
      @include fillSpace;
      z-index: 3;
      @include transparentBG($alt, .75);
      // .loading &{
      //   background: transparent;
      // }
    }

    &.active{
      a{
        background: transparent;
        &:before{
          @include useGPU;
          @include pseudoContent;
          @include fillSpace;
          position: absolute;
          bottom: 0px;
          left: 0px;
          background: linear-gradient(to top, rgba(0,0,0,0.95) 5% , rgba(0,0,0,0) 45%);
          opacity: 1;
          @include transition(.75s);
        }
        .loading &{
            &:before{
              opacity: 0;
          }
         }
      }
      .overlayHolder{
        .bg{
          background:$primary;
        }
      }
      .titleWrap{
        animation: btnTitle 2.25s;
      }
      .icon-check{&:before{content: 'o';}}
      .iconWrap{
        background: $primary;
        border: 3px solid $light;
        font-size: 2.5em;
        height: 60px;
        width: 60px;
      }
      @media (min-width: $sm + 1){
        &:hover{
          .overlayHolder{
           top: 0px;
           left: 0px;
           .bg{
             opacity: .8;
           }
          }
          .iconWrap{
            background: $accent;
            width: 80px;
            height: 80px;
            font-size: 3.2em;
            margin-bottom: 50px;
            @include horzCenter;
          }
          h2{
              @include horzCenter;
              font-size: 1.95em;
          }
        }
      }
    }
    &.completed{
      a{
        @include transparentBG($primary, .70);
      }
      .iconWrap{
        background: $secondary;
      }
    }
    &.active,&.completed{
      .bgImg{
        @include grayscale($value: 0);
      }


      .icon-check{display: block;}
      .titleWrap{
        color:$light;
        opacity: 1;
        .locked{display: none !important;}
        .sectionIcon{display: block !important;}
      }
      .overlayHolder{display: block;}

    }

    .section-title{display: none;}
    .icon-check{
      display: none;
      position: absolute;
      bottom: 10px;
      right: 10px;
      color:$light;
      font-size: 1.8em;
      z-index: 4;
      @include transition($speed: .75s);
      .loading &{
        right: -100px;
      }
    }
    .titleWrap{
      position: absolute;
      z-index: 3;
      color:$grey;
      opacity: .5;
      bottom: 0px;
      left: 0px;
      padding: 0px 50px 25px 20px;
      width: 100%;

      .sectionIcon{display: none !important;}

    }
    .iconWrap{
      width: 50px;
      height: 50px;
      color: $light;
      background: $grey;
      text-align: center;
      @include rounded(50%);
      position: relative;
      margin-bottom: 15px;
      font-size: 2em;
      @include horzCenter(0%, 0%);
      @include transition($speed: .25s);
      .icon{
        display: block;
        width: 100%;
        line-height: 1em;
        @include vertCenter;
        position: absolute;
      }
    }


    h2{
      text-transform: uppercase;
      @include transition($speed: .25s);
      display: inline-block;
      @include horzCenter(0%, 0%);
    }

    .overlayHolder{
      display: none;
      position: absolute;
      left: calc(100% - 200px);
      top: calc(100% - 50px);
      width: 100%;
      height: 100%;
      @include useGPU;
      @include transition($speed: .35s);
      z-index: 2;

      .bg{
        position: absolute;
        top: -525px;
        left: -250px;
        background: $secondary;
        border: 3px solid $light;
        width: 900px;
        height: 900px;
        @include rotate(47deg);
        transform-origin: bottom left;
        animation: showCorner 1.75s;
      }
    }
    .sectionIcon{
        display: none;
    }
    &:nth-child(odd){

      .bgImg{
        animation: menuBtnsOddIn 1.25s;
       left: 0px;
         top: 0px;
      }
    }
    &:nth-child(even) {

      .bgImg{
        animation: menuBtnsEvenIn 1.25s;
       right: 0px;
       bottom: 0px;
      }
    }
    .bgImg{
      @include useGPU;
      position: absolute;
      @include fillSpace;
      @include backgroundCover;
      @include grayscale;
      @include transition($speed: 1s);

    }
  }

}
// #homeScreen.out1 {
// //  top: 0px;
//   #main-nav{
//   //  border: 0px;
//     // height: 100%;
//     .button{
//       &:not(.clicked){
//        width: 0px;
//        height: 0px;
//       }
//       // &.clicked{
//       //   width: 100%;
//       //   height: 100%;
//       //
//       //   .bgImg{opacity: .5;}
//       // }
//     }
//   }
// }

#homeScreen.out {
//  top: 0px;
  #main-nav{
  //  border: 0px;
    //height: 100%;
    .button{

      &:not(.clicked){
      //width: 0px;
       height: 0px;
      }
      &.clicked{
        width: 100%;
        height: 100%;

        .bgImg{opacity: .5;}
      }
    }
  }
}
// #homeScreen.out2 {
//   #main-nav{
//
//     .button{
//       margin: 0px;
//     }
//    }
// }
.inSection{
  #homeScreen{top: 0px;}
  #main-nav{
   height: 100%;

    .button{
      margin: 0px;
    }
   }
}
.in2{
  #homeScreen{
    &:after{
      width: 100%;
    }
    #main-nav{
      .button{
        &:not(.clicked){
          display: none;
        }
      }
    }
  }
}

@media (max-width: $lg - 80px){
  #main-nav{
    .button{
      h2{
        font-size: 1.25em;
      }
    }
  }
}

@media (max-width: $md){
  #main-nav{
    .button{
      margin: 3px;
      .overlayHolder{
        left: calc(100% - 175px);
        top: calc(100% - 50px);
      }
      .icon-check{
        font-size: 1.6em;
      }
    }

  }
}

@media (max-width: $sm){
  #main-nav{
    align-content: flex-start;
    height: auto;
    padding-bottom: 120px;
    width: 95%;
    margin: 0px auto;
    margin-top: 135px;
    .button{
      width: 30%;
      height: 250px;
      margin: 4px;
    }
  }
}

@media (max-width: $xs){
  #main-nav{
    align-content: flex-start;
    height: auto;
    padding-bottom: 120px;
    width: 95%;
    margin: 0px auto;
    margin-top: 100px;
    .button{
      width: 45%;
      height: 250px;
      margin: 4px;
    }
  }
}


@media (max-width: $xxs){
  #main-nav{
    padding-bottom: 110px;
    width: 98%;
    margin-top: 100px;
    .button{
      height: 200px;
    }
  }
}

@media (max-width: $xxs - 50){
  #main-nav{
    .button{
      height: 150px;
      margin: 2px;
      .icon-check{
        font-size: 1.2em;
        bottom: 5px;
        right: 5px;
      }
      .overlayHolder{
      //  left: calc(100% - 165px);
        left: calc(100% - 115px);
        top: calc(100% - 40px);
        .bg{
          transform: rotate(43deg);
        }
      }
      .titleWrap{
        padding-bottom: 10px;
        padding-left: 10px;
      }
    }
  }
}

@media (max-width: $xxs - 110){
  #main-nav{
    .button{
      .icon-check{
        right: 2px;
      }
      .overlayHolder{
      //  left: calc(100% - 165px);
        left: calc(100% - 50px);
        top: calc(100% - 40px);
        .bg{
          transform: rotate(38deg);
        }
      }
    }
  }
}
