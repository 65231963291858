
.videoSlide{
    .videoWrapper{
         padding-right: 0 !important;
    }
    .videoContainer{
      margin-bottom: 15px;
    }

    &.vidBelow{
      .content{
        max-width: 1150px;
        margin: 0 auto;
        padding: 0px 50px;
      }
        .slideSide{
            width: 100%;
            margin: 0 auto;
            padding-bottom: 25px;
            padding-top: 40px;
            float: none;
            .tips{
                display: none;
            }
        }
        .slideCopy{
            width: 100% !important;
            margin: 0 auto !important;
            float: none !important;

        }
    }
}
.videoWrapper {
    .loading{
        display: block;
        color: $grey;
        font-size: 1.2em;
        font-family: $primaryFont;
        text-align: center;
        padding-top: 40px;
        text-transform: uppercase;
    }
    &.hideLoad{
      .videoHolder, #videoHolder{background: none;}
    }
}

  .autoplaySet{display: none;}

//***4:3 RATIO VIDEOS***//

/*#videoHolder {
    position: relative;
    padding-bottom: 75%;//for 4:3 ratio videos
    padding-top: 0;
    height: 0;
    margin-bottom: 15px;

    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
}*/

//***16:9 RATIO VIDEOS***//

.videoHolder,#videoHolder {
  background: #000 url('../images/load-screen.jpg') no-repeat;
background-size: cover;
    position: relative;
    padding-bottom: 56.25%;//for 16:9 ratio videos
    padding-top: 0;
    height: 0;
    margin-bottom: 15px;
    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
}

@media (max-width: $lg){
  .videoSlide{
    &.vidBelow{
      .slideSide{
        padding-top: 25px;
      }
    }
  }
}

@media (max-width: $xs){
  .videoSlide{
    &.vidBelow{
      .content{
        padding: 0px 20px;
      }
    }
  }
}
