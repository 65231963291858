
.slideTop{
    @include fixed;
    width: 100%;
    border-bottom: 1px solid $light;
    top: -100px;
    height: $timer;
    z-index: 5;
    .wrap{
      // max-width: 1500px;
      padding: 0px 50px;
    }

    .in2 &{
      top: $smHeader;
    }

}

.currentSectionTitle{
  font-family: $primaryFont;
  font-weight: 700;
  z-index: 2;
  position: relative;
  text-transform: uppercase;
  color:$light;
  font-size: 1.3em;
  display: inline-block;
  padding-top:3px;
}


.progressBar{
  position: fixed;
  bottom: 0px;
  right: -40px;
  @include footerHeight;
  width: 150px;
  height:$arrows;
  transform: skew(-38deg);
  background: $secondary;
  z-index: 16;
  padding-right: 50px;
  font-family: $primaryFont;
  font-weight:400;
  font-size: 2em;
  border-left: 3px solid $light;
  text-align: center;
  .progress{
    transform: skew(38deg);
    display: inline-block;
    color: $light;
    @include vertCenter;
    transform: translateY(-50%) skew(38deg);
    //padding-top: 30px;
    right: -8px;

    span{display: inline-block;}
  }
  .num{
    line-height: 1em;
    display: inline-block;

  }
  .pos{
    border-right: 2px solid $light;
    padding-right: 6px;
    margin-right: 8px;
    display: inline-block;
  //  @include vertCenter;
  }

}

.innerIcon{
    display: inline-block;
    height: 50px;
    width: 50px;
    text-align: center;
    @include rounded(50%);
    color:$light;
    //float: right;
    background: $primary;
    border: 2px solid $light;
    padding-top: 5px;
    font-size: 2.2em;
   position: relative;
  //  top: 25px;
  .locked{display: none !important;}
  .sectionIcon{
    display: inline-block;
  }
}

.timer{
    @include absolute;
     width: 100%;
     height: 100%;
     z-index: 1;
   @include transparentBG($primary, .3);

    .inner{
        @include absolute;
        height:100%;
        background: $secondary;
        width: 0px;
    }
}

@media (max-width: $md + 100){

  .slideTop{
    .wrap{
      padding: 0px 30px;
    }
  }
}

@media (max-width: $xs){
  .in2{
    .slideTop{
      top: auto;
      position: relative;
    }
  }
  .slideTop{
    height: 30px;
    .wrap{
      padding: 0px 25px;
    }
  }
  .currentSectionTitle{
    font-size: 1.15em;
  }
  .progressBar{
    padding-right: 55px;
    font-size: 2em;
    right: -60px;
  }
  .innerIcon{
    font-size: 1.9em;
    height: 45px;
    width: 45px;
  }
}
