#inner-content {   //important
	position:relative;
    display:none;
    z-index: 9;
		margin-top: $smHeader + $timer;

    .slideHolder{   //important
        @include absolute;

        width:100%;
        min-height: 500px;
	    display: none;
        padding-bottom: 70px;
				&.current{
					.slideCopy{
						 margin-top: 0px;
							opacity:1;
					}
					.slideSide{
						margin-bottom: 0px;
						opacity: 1;
					}
        }
        &.movingIn{
					.slideCopy{
						 margin-top: 0px;
							opacity: 1;
					}
					.slideSide{
						margin-bottom: 0px;
						opacity: 1;
					}
        }
         &.movingOut{
             .slideCopy{
                margin-top: -200px;
                 opacity: 0;
             }
						 .slideSide{
							 margin-bottom: -200px;
							 opacity: 0;
						 }
        }

    }
}

//BOOTSTRAP DEAFUALTS//
.slideCopy{
	width: 50%;
	float: left;
	position: relative;
  min-height: 1px;
	@include transition($speed:.5s);
}
.slideSide{
	float: left;
	width: 50%;
	position: relative;
    min-height: 1px;
    padding-left: 0;
    padding-right: 0;
		@include transition($speed:.5s);
}
//BOOTSTRAP DEAFUALTS//


.slideBG{
	@include pseudoContent;
	@include fixed;
	width: 5px;
	height: 100%;
	background: $light;
	z-index: 5;
	top: $smHeader + $timer;
	@include transition(width, .5s);
}
.in2{
	.rspContent{
		width: 54%;
		&.vidContentW{
			float: left;
			width: calc(100% - 60%);
		}
		&.quizContentW{
			width: calc(100% - 54%);
		}
		/* Ben added this */
		&.fullContentW {
			width: 100%;
		}
		&.centContentW{
			@include horzCenter;
			position: fixed;
			//width: 70%;
			width: 100%;
		}
	}
	.rspSide{
		width: calc(100% - 54%);
		padding: 20px;
		&.vidSideW{
			float: right;
			width: 60%;
		}
		&.quizSideW{
			width: 54%;
		}
		&.fullSideW {
			width: 100%;
		}
		&.centSideW{
			width: 100%;
		}

	}
}


.errorPage .sectionBGs{
    display:block;
    #button1BG{
        display:block;
    }
}


.content{
    font-family: $basicFont;
    font-size: 1.1em;
    line-height: 1.65em;
    font-weight: 400;
}


.slideTitle{
    font-family:$primaryFont;
		text-transform: uppercase;
    text-align: left;
    font-size: 1.8em;
    padding: 10px 10px 10px 50px;
    line-height: 1.25em;
		background: $primary;
		color: $light;
}

.slide {


    .table-responsive{
        @media all and (max-width: $sm){
            border:2px solid lighten($dark,10%);
            &:before{
                content:'Swipe from left to right to view the entire table of information.';
                display:block;
                background:lighten($dark,10%);
                padding:5px;
                text-align: center;
                font-size: .85em;
                color: white;
            }
        }
        td{
            border-top:0;
        }
    }
    a{
        &[href*='.pdf']{
            &::before{
                font-family:$iconFont;
                content: "o";
                font-size: 1em;
                padding-right:3px;
                display:inline-block;
            }
        }
    }
}


.slideCopy{
	.copy{
		padding: 30px 50px 0px;
	}
    li{
        margin-bottom: 7px;
    }
}

.imgWrap{
    width: 100%;
    margin: 0 auto;
		text-align: center;
    img{
        max-width: 100%;
        height: auto;
				display: inline-block;
    }
}

//Text Highlight based on School Colors
/*
::selection {
    background: $primary;
    color:$light;
}
::-moz-selection {
    background: $primary;
    color:$light;
}
*/

@media (max-width: $md + 100){
		.slideTitle{
			padding-left: 30px;
		}
		.slideCopy{
			.copy{
				padding: 30px 30px 0px;
			}
		}
}

@media (max-width: $sm){
	.in2 {
		.rspContent{
			width: 100%;
			&.vidContentW{
				width: 100%;
				.copy{
					padding-top: 0px;
				}
			}
			&.quizContentW{
				width: 100%;
			}
		}

		.rspSide, .rspSide.vidSideW, .rspSide.quizSideW{
			width: 100%;
		}
		.slideSide{
			float: none;
			position: static;
		}
		.slideCopy{
			float: none;
		}
	}
}

@media (max-width: $xs){
    #inner-content{
			margin-top: 0px;
			.slideHolder{
				top: 30px;
			}
		}

		.slideTitle{
			padding-left: 25px;
			font-size: 1.6em;
		}
}

@media (max-width: $xxs){

		.slideTitle{
			padding-left: 25px;
			font-size: 1.4em;
		}
}
