

.arrowNav{
    @include clearfix;
    @include borderBox;
    margin-top: 0px;
//    overflow: hidden;
    position: fixed;
    width: 0px;
    right: 0px;
    z-index: 15;
    opacity: 1;
    bottom: -150px;
  //  height: $arrows !important;
    @include footerHeight;
    background: $primary;
    overflow: hidden;
    padding: 0px !important;
    @include transition($speed: .5s);
    padding-right: 130px !important;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    .in2 & {
      .rspSide.centSideW{
          justify-content: flex-end;
      }
      bottom: 0px;
    }
    .arrow{
    //  float: right;
        cursor: pointer;
        &.disabled{
            opacity: .25;
            &:hover{
                cursor: not-allowed;
            }
        }
        span{
            font-size: 6em;
            color: $light;
        }
        html:not(.mobile) &{
        &:hover{
          span{
            color: $contrast;
          }
        }
      }
    }
    #arrowLeft{
        left:0px;
        span{
        }
    }
    #arrowRight{
        right:0px;
        span{
        }
    }

}

@media (max-width: $lg){
  .arrowNav{
    .arrow{
      span{
        font-size: 4.6em;
      }
    }
  }
}
@media (max-width: $xs){
  .arrowNav{
    padding-right: 0px !important;
    justify-content: center;
    .arrow{
      padding: 0px 20px;
      span{
        font-size: 4em;

      }
    }
  }
}

@media (max-width: $xxs){
  .arrowNav{
    justify-content: flex-end;
    #arrowLeft{
      padding-left: 10px;
    }
    #arrowRight{
      padding-right: 10px;
    }
  }

}
