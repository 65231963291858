#searchModeWrap {
  @include fixed;
  @include fillSpace;
  @include clearfix;
  z-index: 20;
  display: none;
  .searchMode & {
    display: block;
    .searchResultsPanel {
      right: 0;
    }
  }
  //Area to hold slide
  .searchSlideHolder {
    @include searchAreaW;
    position: absolute;
    top: 110px;
    height: calc(100% - 110px);
    left: 0;
    //height: 100%;
    overflow: auto;
    @include transparentBG($basic, .5);
    .searchMode & {
      opacity: 100 !important;
    }
    .slide {
      //height: 100%;
      background: $light;
      display: none;
      min-height: 100% !important;
      //padding: 0 0 40px !important;
      #learnMoreWrapper {
        display: none !important;
      }
      .slideTitle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        font-family: $primaryFont;
        display: block;
        text-shadow: none;
        color: $light;
        font-weight: 400;
        margin-bottom: 25px;
        background: $secondary;
        font-size: 2.5em;
        padding: 30px;
        height: 110px;
      }
      .content {
        //padding: 0 30px 25px;
      }
      .slideCopy {
        margin-top: 30px;
        .copy {
          padding: 0 30px 0;
        }
      }
      .slideSide {
        margin-top: 30px;
        padding: 0 30px;
      }
    }
  }
  //Area that holds search results
  .searchResultsPanel {
    @include searchBarW;
    position: absolute;
    right: 0;
    top: 110px;
    height: calc(100% - 110px);
    overflow: auto;
    opacity: 0;
    @include transition($speed: .4s);
    overflow-y: scroll !important;
    /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    .searchMode & {
      opacity: 1;
    }
  }
  .searchResultsContent {
    //margin-top: 110px;
  }
}
.ezsearch-summary {
  color: $light;
  padding: 20px 25px 0;
  font-size: 1.1em;
  margin-bottom: 5px;
  strong {
    font-family: $primaryFont;
    font-weight: 700;
  }
  p {
    @include zeroMargPad();
  }
}
.ezsearch-result-count {
  color: $light;
  font-style: italic;
  font-size: 0.85em;
  padding: 0 25px;
  //text-align: center;
  p {
    @include zeroMargPad();
  }
  a {
    &:hover {
      color: $link;
      text-decoration: underline;
    }
  }
}
.ezsearch-results {
  padding-top: 20px;
}
.ezsearch-result {
  margin-bottom: 0;
  padding: 18px 25px;
  position: relative;
  overflow: hidden;
  min-height: 141px;
  //border-top: 3px solid $light;
  //border-bottom: 3px solid $light;
  .locked {
    .preview,
    .slideTitle,
    .topic {
      opacity: 0.5;
    }
    position: relative;
    &:hover {
      cursor: not-allowed;
      background: transparent;
      .slideTitle {
        color: $secondary;
      }
    }
    .lockedMsg {
      display: none;
      @include absolute;
      width: 100%;
      top: 25px;
      background: $secondary;
      color: $light;
      font-weight: 700;
      padding: 15px;
      text-align: center;
      font-size: 1.1em;
    }
  }
  .resultContent {
    position: relative;
    left: 0;
    @include transition($speed: .3s);
  }
  &.clicked {
    .resultContent {
      left: -90px;
    }
    .viewOptions {
      right: 0;
    }
  }
  &:hover {
    cursor: pointer;
    background: $light;
    a {
      color: $secondary;
    }
    p {
      color: $basic;
    }
    .topic {
      color: $secondary;
    }
  }
  &.viewing {
    background: $secondary;
    a {
      color: $light;
    }
    .preview {
      color: $light;
    }
    .topic {
      background: transparent;
      color: $light;
    }
  }
  .slideTitle {
    font-weight: 700;
    text-transform: uppercase;
    color: $light;
    background: none;
    font-size: 1.1em;
    padding: 0 0 7px;
    display: block;
    text-shadow: none;
  }
  a {
    color: $light;
    display: inline-block;
  }
  .preview {
    color: $light;
    @include zeroMargPad();
    font-size: 0.95em;
    margin-bottom: 10px;
  }
  .topic {
    font-family: $primaryFont;
    color: $light;
    font-size: 0.8em;
    text-transform: uppercase;
  }
}
.viewOptions {
  width: 60px;
  height: 100%;
  margin: 10px auto;
  position: absolute;
  @include transition();
  right: -60px;
  top: -10px;
  .view {
    text-align: center; //        border-bottom: 2px solid $primary;
    height: 48%;
    background: $secondary;
    padding-top: 20px;
    @include borderBox;
    margin-bottom: 3%;
    margin-top: 3%;
    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
      height: 48%;
    }
    &:hover {
      background: $accent;
      cursor: pointer;
      p {
        font-size: 0.85em;
      }
      .icon {
        font-size: 0;
        margin-top: -65px;
      }
    }
    p {
      @include zeroMargPad;
      @include transition();
      font-family: $primaryFont;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0;
      margin-top: -20px;
      color: $light;
    }
    .icon {
      @include transition();
      color: $light;
      font-size: 2em;
      line-height: 1em;
    }
  }
}
.ezsearch-pager {
  text-align: center;
  background: $light;
  padding: 5px;
  color: $light;
  p {
    margin: 5px 0;
  }
  span {
    font-weight: bold;
  }
  a {
    color: $light;
    font-weight: normal;
    &:hover {
      text-decoration: underline !important;
    }
  }
}
.ezsearch-no-results {
  background: $secondary;
  color: $light;
  font-weight: 700;
  font-family: $primaryFont;
  padding: 3px 5px;
  margin: 0 25px;
  text-align: center;
  p {
    @include zeroMargPad;
  }
  strong {
    text-decoration: underline;
    font-size: 1.1em;
  }
}
.quickViewing {
  #searchModeWrap .searchSlideHolder {
    background: $light;
  }
}
.lockedBtn {
  display: none;
}
.searchBtn {
  .inner{
  .searchMode & {

    @include transition();
    border: 2px solid $accent;
    .icon {
      color: $light;
      &:before {
        content: 'b';
      }
    }
    &:hover {
      background: darken($primary, 10%);
    }

  }
}
  &:hover {
    .inner{
    background: $primary;
    color: $light;
    cursor: pointer;
  }
  }
}
.inSection {}
.searchArea {
  position: fixed;
  top: 0;
  height: 110px;
  padding: 30px 60px 20px 30px;
  //background: $light;
  @include searchBarW
  //  padding-right:
  //padding-right: 45px;
}
.ezsearch-form {
  .searchField {
    border-radius: 10px 0 0 10px;
    height: 50px;
    padding: 5px 10px;
    font-size: 1.2em;
    font-family: $primaryFont;
    font-weight: 400;
    color: $basic;
    width: calc(100% - 100px);
    border: 1px solid $grey;
    box-shadow: none;
    &:focus {
      outline: none;
    }
  }
  .btn {
    @include iconFont;
    border-radius: 0 10px 10px 0;
    position: relative;
    margin-left: -5px;
    top: 3px;
    border: 1px solid $grey;
    // right:60px;
    height: 50px;
    width: 50px;
    background: $primary;
    color: $light;
    font-size: 1.5em;
    &:hover {
      background: $accent;
      color: $light;
    }
  }
}
.summaryWrap {
  position: relative;
  width: 90%;
  margin: 0 auto;
}
.resultsBtn {
  background: $secondary;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 3px 10px 3px 35px;
  color: $light;
  text-transform: uppercase;
  font-family: $primaryFont;
  font-weight: 700;
  font-size: 0.9em;
  .icon {
    margin-right: 5px;
    font-size: 1.5em;
    position: absolute;
    bottom: -5px;
    left: 7px;
  }
}

@media (max-width: $lg) {
  .ezsearch-form{
    .searchField{
      width: calc(100% - 70px);
    }
  }

}


@media (max-width: $md) {
  #searchModeWrap {
    .searchSlideHolder {
      width: 0;
    }
    .searchResultsPanel {
      width: 100%;
    }
  }
  // .searchMode {
  //   .searchBtn {
  //     right: auto;
  //     left: 0;
  //     top: 13px;
  //     height: 50px;
  //     width: 50px;
  //     padding-top: 5px;
  //   }
  // }
  .searchArea {
    padding-left: 40px;
    background: $light;
  }
  .ezsearch-form {
    .searchField {
      height: 55px;
    }
    .btn {
      height: 55px;
    }
  }
}

@media (max-width: $sm) {
  .alertBar{
    .searchMode &{
      bottom: auto;
      top: 40px;
      z-index: 600;
    }
  }

}


@media (max-width: $xs) {
  .searchArea {
    width: 100%;
    padding: 30px 60px 20px 10px;
  }
  .alertBar{
    .searchMode &{
      justify-content: flex-start;
      width: 50px;
    }
  }
}
