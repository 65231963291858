.popupMessage{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	@include transparentBG($basic,0.85);
	z-index:9999;
    .logoWrap{
        max-width:none;
        margin:-25px;
        padding:10px;
        background:$light;
        text-align:center;
        height:84px;
        margin-bottom:13px;
        .logo{
            max-height:100%;
            height:auto;
            margin:0 auto;
        }
    }
    .message{
        width:100%;
        position:absolute;
        left:0;
        top:-30%;
        margin:0;
        @include transparentBG($secondary,0.75);
        padding:25px 25px 45px;
        @include boxShadow;
        @include transition($speed: .4s);
        @media all and (max-width: $xs){
            top:0!important;
        }
    }
    h4{
        @include headerFont;
        font-size: 2.8em;
        font-weight: 400;
        text-align:center;
        color: #fff;
        margin:10px 0 10px 0;
        text-transform: uppercase;
        @media all and (max-width: $xs){
            font-size:2em;
        }
    }
    p {
        text-align:center;
        margin:0 20px;
        color:#fff;
        font-size: 1.2em;
    }

    .continue{
        display:block;
        width:100px;
        padding:0;
        margin:25px auto 0 auto;
        text-transform: uppercase;
        color: $light;
        font-weight:bold;
        cursor: $cursor;

        span{
            @include btnStyle;
            background: $primary;
            border: 2px solid $light;
            }
    }

    &#timeoutMessage{
        .btnWrap{
            width: 90%;
            max-width: 600px;
            margin: 50px auto 20px auto;
            text-align: center;
            span{
                display:inline-block;
                margin-right:15px;
                position: relative;
                background: $primary;
                padding: 10px 20px 10px 20px;
                color: $light;
                font-family:$primaryFont;
                font-size:1.125em;
                text-align:center;
                &:hover{
                    background:$accent;
                    //opacity: .75;
                }
                &.stayBtn{
                    background:$light;
                    color:$primary;
                    &:hover{
                      //opacity: .75;
											background:$accent;
											color: $light;
                    }
                    em{
                        &:before{
                            content:'\61';
                        }
                    }
                }
                em{
                    display:block;
                    font-style:normal;
                    font-weight:600;
                    text-transform:uppercase;
                    font-size:1.25em;
                    &:before{
                        content:'\62';
                        font-family:$iconFont;
                        padding-right:10px;
                        text-transform:none;
                    }
                }
            }
        }
         &.show{
            display: block;
            .message{
                top:0px;
            }
        }
    }
}

@media (max-width: $xs){
    .popupMessage{
        h4{
            font-size: 2.2em;
        }
        .message{
            padding: 5px 15px 25px;
        }

    }
}

@media (max-width: $xxs){
    .popupMessage#timeoutMessage{
         h4{
            font-size: 1.8em;
        }
        .btnWrap{
            max-width: 300px;
            margin-top: 25px;
            span{
                display: block;
                margin: 5px 0px;
            }
        }
    }
}
