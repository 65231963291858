#helpBG {
  display: none;
  @include transparentBG($basic, .7);
  @include fixed;
  @include fillSpace;
  z-index: 400;
}
#helpContent {
  z-index: 401;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  @include fillSpace;
  .arrowNav {
    display: block;
  }
}
#helpCarousel {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 0 95px;
  background: $light;
  color: $basic;
  top: -400px;
  @include transition($speed: 1s);
  //border-top: 45px solid $basic;
  &.showing {
    top: 0;
  }
  .carousel-inner {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
  }
  .item {
    width: 100%;
    margin: 0 auto;
    padding: 0 100px 45px;
    margin-top: 30px;
  }
  .titleWrap {
    background: $primary;
    padding: 10px 0;
    text-align: center;
  }
  h1 {
    text-transform: uppercase;
    color: $light;
    font-family: $primaryFont;
    font-weight: 700;
    font-size: 1.7em;
    font-size: 1.8em;
  }
  h3 {
    @include headerFont;
    font-weight: 600;
    font-size: 1.1em;
    color: $secondary;
    margin: 15px 0 5px;
  }
  .helpSlideTitle {
    font-weight: 600;
    font-size: 1.8em;
    color: $primary;
    margin: 15px 0 5px;
  }
  .copy {
    font-family: $basicFont;
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.45em;
    color: $basic;
    ul {
      li {
        padding-bottom: 8px;
      }
    }
    p {
      @include zeroMargPad;
      padding-bottom: 10px;
    }
    img {
      max-width: 100%;
      height: auto;
      @include boxShadow();
    }
    h2 {
      font-size: 1.8em;
    }
  }
  .helpArrow {
    position: absolute;
    left: 0;
    width: 100%;
    top: 25%;
    .arrow {
      //background: $primary;
      top: auto;
      position: absolute;
      width: 60px;
      padding-top: 8px;
      span {
        color: $secondary;
        font-size: 3.5em;
        position: relative;
        z-index: 2;
        transition: color 200ms;
      }
      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 1px;
        height: 100%;
        top: 0;
        background-color: $primary;
        z-index: 1;
        transition: width 200ms;
      }
      &:hover {
        //background: $hover;
        text-decoration: none;
        span {
          color: $light;
        }
        &:before {
          width: 100%;
        }
      }
      &:focus {
        text-decoration: none;
      }
    }
    .left {
      left: 5px;
      padding-left: 3px;
      &:before {
        right: 0;
      }
    }
    .right {
      right: 5px;
      padding-left: 8px;
      &:before {
        left: 0;
      }
    }
  }
  .carousel-indicators {
    bottom: -15px;
    li {
      border: 2px solid $secondary;
      &.active {
        background: $contrast;
      }
    }
  }
  .helpBtnWrap {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    .lbClosebtn {
      @include btnStyle;
      position: static;
    }
    &.disabled {
      .lbClosebtn {
        background: $grey;
        color: lighten($dark, 60%);
        &:hover {
          cursor: not-allowed;
        }
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.inSection {
  .helpBtn {
    display: none;
  }
}
@media (max-width: $sm) {
  #helpContent {
    background: $light;
    top: 0;
  }
  #helpCarousel {
    &.showing {
      top: 0;
    }
    h1 {
      // padding-top: 10px;
    }
  }
}
@media (max-width: $xs) {
  #helpContent {
    top: 0;
    position: absolute;
  }
  #helpCarousel {
    &:before {
      display: none;
    }
    .helpArrow {
      position: fixed;
      top: auto;
      bottom: 0;
      width: 100%;
      height: 45px !important;
      background: $primary;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .arrow {
        position: relative;
        width: 50%;
        height: 100%;
        padding-top: 0;
        border-bottom: 0;
        text-align: center;
        span {
          font-size: 2.5em;
          @include vertCenter;
          display: inline-block;
          color:$light;
          &:before {
            padding-top: 0px;
          }
        }
      }
    }
    h1{
      font-size: 1.5em;
    }
    .helpSlideTitle {
      font-size: 1.8em;
    }
    .copy {
      li {
        margin-bottom: 7px;
      }
      ul {
        margin: 0;
      }
      p {
        @include zeroMargPad;
        padding-bottom: 10px;
      }
      img {
        max-width: 100%;
        height: auto;
        @include boxShadow();
      }
      h2 {
        font-size: 1.8em;
      }
    }
    .helpBtnWrap {
      .lbClosebtn {
        font-size: 1.5em;
      }
    }
    .item {
      width: 100%;
      padding: 0 20px 45px;
    }
    .helpSlideTitle {
      margin-top: 5px;
      padding-top: 5px;
    }
  }
}
@media (max-width: $xxs) {
  #helpCarousel {
    padding: 0 0 80px;
    font-size: 0.9em;
    h1 {
      max-height: 100%;
    }
  }
}
