/**VARIABLES**/
//Colors
$primary: #007073; //Teal
$secondary: #003366; //Navy
$accent: #af7f1b; //Dark Gold
$alt: #b9e1e0; // Light Teal
$alt2: #6ec5c2;//Bright Teal
$contrast: #e3a82b; //Gold
$link: #005369;  //DArk TEal
$basic: #00151a;  //Cool Black
$hover: $accent;


$light: #fff;    //White
$dark: #667882;    //Charcoal Grey
$grey: #747a7e;    //Grey

$mainBG: darken($grey,10%); // grey
$bodyBG: $light; //White

$wrong: #c56523; //orange
$correct: #b6b835; // Green

$error: #b20505; //POomegranate

//Fonts
$primaryFont: 'Open Sans',"Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$secondaryFont: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$basicFont: 'Open Sans',"Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$iconFont: 'uncw';



//OLD Media Query Resolutons
$width-xxsm: 480px;
$width-xsm: 600px;
$width-sm: 800px;
$width-md: 1050px;
$width-lg: 1280px;
$width-xlg: 1500px;

//OLD Media Query Resolutons
$tn : 600px;
$xs : 800px;
$sm : 1050px;
$md : 12800px;
$max : 1500px;


//New Widths
$xxs: 480px;
$xs: 630px;
$sm: 820px;
$md: 1050px;
$lg: 1280px;
$xl: 1500px;


//Sizes
$progress : 20px;
$headerHeight:135px;
$footerHeight: 90px;
$infoSize: 50px;
$smHeader: 80px;
$maxWidth: 1400px;
$timer: 35px;
$arrows: 100px;
$logoADAMax: 300px;

//Elements
$cursor: pointer;
