#learnMoreWrapper {
  display: none;
  cursor: pointer;
  padding: 0;
  margin-top: 30px;
  position: relative;
  @include clearfix;
  @include transition;
  padding: 15px 14px 15px 80px;
  color: $primary;
  margin-left: -20px;
  // @include transparentBG($grey, .2);
  &:after {
    visibility: visible;
    @include transition(width,.4s);
    position: absolute;
    top: 0;
    right: 0;
    @include fillSpace;
    @include pseudoContent;
    background: $light;
    z-index: -1;
    width: 0;
  }

  html:not(.mobile) & {
    &:hover {
      color: $accent;
    }
  }

  &.selected {
    color: $light;

    &:after {
      width: 100%;
      background: $secondary;
    }

    html:not(.mobile) & {
      &:hover {
        color: $light;
        &:after {
          width: 100%;
          background: $accent;
        }
      }
    }

    .iconWrap {}

    .icon {
      animation: emphasizeIcon 0.6s;

      &:before {
        content: 'l';
      }
    }
  }

  .iconWrap {
    @include vertCenter;
    position: absolute;
    left: 10px;
    text-align: center;
  }

  .icon {
    font-size: 3.5em;
  }
}

.toolTip {
  opacity: 1;
  padding: 0 5px 0 0;
  font-family: $primaryFont;
  font-weight: 700;
  font-size: 1.2em;

  p {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

.lmNote {
  font-family: $primaryFont;
  font-weight: 400;
  padding: 0;
  overflow: hidden;
  font-size: 1em;
  line-height: 1.5em;
  opacity: 0;

  &.show {
    opacity: 1;
    margin-top: 5px;
  }
}

.learnMore {
  //background: $light;
  .slideCopy {
    width: calc(100% - 260px);
    display: none;
  }

  .slideSide {
    max-width: 400px;
    width: 100%;
    float: none;
    margin: 40px auto 0;
    // width: 250px;
    // float: left;
  }

  .learnMoreLinksWrap {
    @include clearfix;
    @include maxWidth;
    margin: 30px auto;
    max-width: 1280px;
    padding: 0px 20px;
    h4 {
      margin-top: 10px;
    }
  }

  .linkGroup {
    font-family: $basicFont;
    padding: 0;
    color: $primary;

    &.default {
      margin: 0 auto;
      background: $alt;
      // background: $dark;
      width: 350px;
      float: left;

      p {
        //color: $light;
      }

      .learnMoreLinks {
        margin-top: 10px;

        li {
          a {
            font-size: 1.2em;
            text-decoration: none !important;
          }
        }
      }
    }

    &.personalized {
      width: calc(100% - 400px);
      float: right;
      background: $light;
      padding-bottom: $footerHeight + 30;

      li {
        a {
          span {
            display: none;
          }
        }
      }

      .learnMoreLinks {
        margin-top: 10px;

        li {
          a {
            padding: 10px 0 10px 45px;

            &:before {
              top: 15px;
            }
          }
        }
      }

      h4 {
        background: $secondary;
        color: $light;
        padding: 5px;
      }
    }

    h3 {
      text-align: center;
      text-transform: uppercase;
      font-family: $basicFont;
      background: $primary;
      color: $light;
      padding: 20px 5px;
      margin-top: 0;

      &:before {
        display: none;
      }
    }

    p {
      text-align: center;
      color: $basic;
    }

    a {}
  }

  .learnMoreLinks {
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
    //  max-width: 575px;
    border-bottom: 0 !important;
    margin-left: 0;

    li {
      display: block;
      font-weight: 400;
      margin-bottom: 0;
      margin-bottom: 5px;
      padding-left: 0;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        display: none;
      }

      a {
        display: block;
        padding: 7px 0 7px 45px;
        font-weight: 700;
        font-family: $primaryFont;
        color: $link;
        font-size: 1.3em;
        position: relative;
        text-decoration: none !important;
html:not(.mobile) &{
        &:hover {
          background-color: $hover;
          color: $light;
        }
      }

        &:before {
          @include iconFont;
          content: "d";
          float: left;
          display: block;
          position: absolute;
          left: 20px;
          top: 10px;
          font-size: 1em;
        }

        &[href*='.pdf'] {
          &:before {
            content: "p";
            font-size: 1em;
          }
        }
      }
    }
  }
}

@media (max-width: $lg) {
  .learnMore{
    .linkGroup{
      &.personalized{
        width: calc(100% - 380px);
      }
    }
  }

}


@media (max-width: $md) {
  #learnMoreWrapper {
    margin-top: 20px;
  }

  .learnMore {
    .learnMoreLinks {
      li {
        a{

        }
      }
    }

    .linkGroup {
      p {
        padding: 5px;
      }
    }
  }
  .learnMore{
    .linkGroup{
      &.personalized{
        width: calc(100% - 350px);
      }
      &.default{
        width: 325px;
      }
    }
  }

}

@media (max-width: $sm){
  .learnMore{
    .linkGroup{
      h3{
        padding: 10px 5px;
      }
      &.personalized{
        width: 100%;
        float: none;
        margin-top: 35px;
      }
      &.default{
        width: 100%;
        float: none;
        ul{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li{
            width: 50%;
          }
        }
      }
    }
  }
}


@media (max-width: $xs) {
  #learnMoreWrapper {
    margin-left: 0;
    padding: 10px 10px 10px 70px;

    .iconWrap {
      left: 5px;
    }
  }

  .toolTip {
    font-size: 1.1em;
    padding-right: 0;
  }

  .learnMore .learnMoreLinks li a {
    font-size: 1.15em;
    padding: 5px 0 10px 35px;

    &::before {
      left: 10px;
    }
  }


  .learnMore .linkGroup h3 {
    font-size: 1.25em;
  }
}
