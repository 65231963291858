.alertBar{
    margin: 0 auto;
    @include clearfix;
    position: fixed;
    right: 45px;
    top: 0px;
    padding: 40px 15px 0px 10px;
    opacity: 1;
    z-index: 15;
    height: $headerHeight - 25px;
    @include transition(right,.4s);

    @media (min-width: $sm + 1){
      animation: alertsIn 1.25s;
    }
    .alertMode &{
        background: $secondary;
        padding-right: 10px;
    }
    .item{
      float: right;
      text-align: center;
      margin-left: 5px;
      font-size: 1.2em;
      line-height: 1em;
      position: relative;

      .alertMode &{
        .inner{
        color: $light;
        border-color: $light;
        opacity: .7;
      }


      }
      .inner{
          @include useGPU;
        color: $secondary;
          padding-top: 7px;
        height: 35px;
        width: 35px;
        display: block;
        overflow: hidden;
        @include rounded(50%);
          border: 2px solid $secondary;
          position: relative;
          @include transition($speed: .2s);
        &:before{
          position: absolute;
          right: -35px;
          bottom: -35px;
          // right: 0px;
          //  bottom: 0px;
          @include pseudoContent;
          background: $accent;
          z-index: 1;
          @include fillSpace;
          @include transition($speed: .2s);
        }
      }
      .icon{
        position: relative;
        z-index:2;
      }

       &:hover{
          cursor: pointer;
         .inner{
           color:$light;

           border-color:$accent;
           background-color: $accent !important;
           transition: background-color 1ms 200ms !important;
           opacity: 1;
          &:before{
            right: 0px;
            bottom: 0px;
          }
        }
        .notify{
          background: $accent;
        }
       }

        &.clicked{
          .inner{
          color: $light;
          border-color: $accent;
          background: $hover;
          opacity: 1;
          &:before{
            right: 0px;
            bottom: 0px;
          }
        }
        }
        &.searchBtn{
          .inner{
          background: $secondary;
          color: $light;
        }
        }
        &.resources{
          //padding-top: 6px;
        }
        &.on{
          margin-right: 4px;
          .notify{
            display: inline-block !important;
          }
        }
      }

    .notify{
      display: none !important;
        font-family: $primaryFont;
        font-weight: 700;
        line-height: 1em;
        font-size: .7em;
        height: 22px;
        width: 22px;
        text-align: center;
        padding-top: 4px;
        position: absolute;
        color: $light;
        right: -6px;
        bottom: -6px;
        background: $secondary;
        @include rounded(50%);
        z-index: 20;
        border: 2px solid $light;

    }
    .alertName{
        display: none;
        @include absolute;
        background: $secondary;
        height: 35px;
        top: -35px;
        width: 100%;
        text-align: center;
        @include borderBox;
        padding-top: 7px;
        text-transform: uppercase;
        color: $primary;
        font-family: $primaryFont;
    }

}

.inSection {
  .alertBar{
    right: -300px;
  }
}

.searchMode{
    .alertBar{
      z-index: 21;
      .item:not(.searchBtn){
        display: none;
      }
    }
}

//ALERT CONTENT AREAS//

#alertContentWrap{
    position: fixed;
    bottom: $footerHeight;
    background:$light;
    padding:0px;
    @include borderBox;
    z-index: 230;
    width: 100%;
    height:calc(100% - 199px);
    display:none;
    overflow-y: scroll !important; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;

    &.open{

    }
    .alertContent{
        display: none;
        padding: 0 0 75px 0px;

    }
    .alertInnerContent{
        padding: 25px 40px;
        max-width: 1250px;
        width: 100%;
        margin: 0 auto;
    }

    .alertListCopy{
            display: table-cell;
            width: 65%;
            padding-left: 25px;
    }
    .alertInstruction{
        display: table-cell;
        width: 35%;
        background: $primary;
        color: $light;
        padding: 25px 30px 40px;
        font-size: 1.3em;
        font-weight: 400;
        @include rounded(5px);
        h2{
            @include headerFont;
            margin: 0 auto !important;
            font-weight: 700 !important;
            font-size: 1.7em !important;
            color: $light !important;
            padding: 5px 0px 10px;
            display: block !important;
            text-transform: uppercase;
        }
    }
    h1{
        font-family: $primaryFont;
        font-weight: 400;
        color: $light;
        font-size: 1.75em;
        padding: 10px 40px 10px 120px;
        margin-bottom: 15px;
        text-transform: uppercase;
        background: $secondary;
        text-align: left;

    }

    #checklist{
        .checkContent{

        }
        .list{
           list-style:none;
           padding-left:35px;
        }
        li{
            font-family: $basicFont;
            border-bottom: 1px solid $grey;
            padding:15px 0 15px 30px;
            color:$basic;
            text-transform: uppercase;
            position: relative;
            @include clearfix;
            &:before{
                @include pseudoContent;
                height: 15px;
                width: 15px;
                border: 2px solid $basic;
                @include absolute;
                top: 20px;
                left: 5px;
                line-height: .5em;
                font-size: 1.2em;
                text-align: center;
                text-transform: none;
            }
            a{
                @include btnStyle;
              //  color:$primary;
                float:left;
                margin:10px 0;
                padding-left: 33px;
                //text-transform: none;
                @include rounded(4px);
                &:hover{
                    color:$light;
                    text-decoration:none!important;
                }
                &:before{
                    font-family:$iconFont;
                    content:'d';
                    display:block;
                    float:left;

                    text-transform: none;

                    left: 15px;
                    position: absolute;
                    top: 10px;
                }
            }
            &.checked{
                opacity: .5;
                &:before{
                    content:'a';
                    font-family: $iconFont !important;
                    text-align: center;
                }
                h3{
                    cursor:default;
                }
            }
        }
        .instruction{
            font-family: $basicFont;
            font-size: 1.25em;
            line-height: 1.7em;
            background: $primary;
            padding: 5px 10px 5px 40px;
            color:$light;

            p{
                @include zeroMargPad;
            }
        }
        h3{
            font-family:$basicFont;
            font-size: 1.3em;
            font-weight: 400;
            text-transform:none;
            padding-bottom:5px;
            margin-left: -26px;
            padding-left: 26px;
            cursor:pointer;
            position: relative;
        }
        .due{
            font-weight: 800;
            text-transform: uppercase;
            font-size: .9em;
            font-family: $basicFont;
            display:block;
            margin: 5px 0;
            clear:both;
            &.noDate {
              display: none;
            }
        }
        a{
          text-decoration: none;
            &:hover{
                text-decoration: underline !important;
            }
        }
    }

    #announcements{
        .announcement{
            border-bottom: 1px solid $grey;
            padding: 20px 0px;
            margin-right:0px;
            font-family: $secondaryFont;
            font-size:1.125em;
            color:$dark;
            &.new{
              //  background-color: darken($light,20%);
              color: $primary;
              h2{
                text-transform: uppercase;
              }

            }
            h2{
                font-family: $secondaryFont;
                font-size: 1.3em;
                padding: 3px 20px;
                &:before{
                    font-family:$iconFont;
                    content: 'e';
                    margin-left: -10px;
                    margin-right: 10px;
                    text-transform:none;
                }
            }
            p{
                @include zeroMargPad;
                padding: 5px 20px 0px 40px;
                line-height: 1.7em;

            }
        }
    }
    #account{
        .accountContent{
            @include clearfix;
            margin-left:40px;

            h2{
                font-family:$secondaryFont;
                font-size:1.5em;
                font-weight:400;
                color:lighten($secondary,25%);
                float:left;
                margin-right:20px;

                span{
                    font-weight:700;
                    color:$basic;
                }
            }
            a{
                @include btnStyle;
                margin:0;
                display:block;
                float:left;
            }
        }
    }
    #resources{
        .slide{
            margin-top:0;
        }

        h1{

        }
        // h3{
        //     font-family:$primaryyFont;
        //     margin:20px 0 10px 0;
        //     font-size: 1.5em;
        // }

        .slideTitle{
            display:none;
        }
        .textCopy{
            padding:0 60px 0 0;

        }
    }
    .closeBtn{
        display: none;
        background: $primary;
        color: $light;
        width: 80px;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.5em;
        padding: 17px 20px 8px 20px;
        text-align: center;
        height: 55px;
        &:hover{
            cursor: pointer;
            color: $light;
            background: $hover;
        }
        &.show{
            display: block;
        }
    }
}
#allowChecked{
    display: none;
    position: fixed;
    top: 20%;
    left: 50%;
    width: 300px;
    margin: 0 0 0 -150px;
    background:$primary;
    padding: 20px;
    z-index: 9999;
    color: $basic;
    text-align: center;
    h1{
        color: $secondary;
        background: transparent;
        margin-bottom: 15px;
        font-size: 2.5em;
        font-family:$secondaryFont;
    }
    span{
        background:$secondary;
        color: $light;
        padding: 5px 10px;
        cursor: pointer;
        @include rounded(4px);
        &:hover{
            color:$light;
            background:darken($secondary,10%);
        }
    }
    p{
        color:$light;
    }
}
#hiddenOverlay{
    display: none;
    @include fixed;
    @include fillSpace;
    z-index: 210;
    @include transparentBG(#000, .85);

}

#wrapper.noOverflow{overflow: hidden; }

@media (max-width: $lg){
  .alertBar{
    right: 25px;
    padding-right: 0px;
  }
}

@media (max-width: $md){
  .alertBar{
    right: 15px;

  }
}

@media (max-width: $sm){
  .alertBar{
    display: flex !important;
    width: 230px;
    height: auto;
    padding: 0px;
    flex-direction: row-reverse;
    align-items: center;
    top: auto;
    bottom: 15px;
    animation: alertsInRsp 1.25s;
    .inSection &{
      display: none !important;
    }
    .item{
      float: none;
      margin: 0px 4px;
      height: 37px;
    }
    .alertMode &{
      bottom: 0px;
      height: 65px;

    }
  }

  #alertContentWrap{
    height: calc(100% - 165px);
    bottom: 65px;
    .alertInstruction{
      padding: 10px 20px;
      display: block;
      width: 100%;
      font-size: 1.1em;
      h2{
        font-size: 1.5em !important;
      }
    }
    .alertInnerContent{
      padding: 10px 20px;
    }
    .alertListCopy{
      width: 100%;
      display: block;
      padding-left: 0px;
    }
  }
  #alertContentWrap #announcements .announcement p{line-height: 1.35em;}
  #alertContentWrap #checklist .list{padding-left: 0px;}
}

@media (max-width: $xs){
  .alertBar{
    right: 0px;
    width: 100%;
    height: auto;
    justify-content: center;
    .notify{
      bottom: -9px;
      height: 26px;
      width: 26px;
    }
    .item{
      font-size:1.5em;
      .inner{
        height: 42px;
        width: 42px;
        padding-top: 9px;
        margin: 0px 5px;
      }
    }

  }
}
