.faqMode {}
/*
.faqBtn{
	background:$light!important;
    border-top:2px solid $primary!important;
    border-right:2px solid $primary!important;
    border-bottom:2px solid $primary!important;
    height: calc(100% - 2px)!important;
    .icon{
        color:$primary!important;
    }
}*/
#faqContentWrap {
	display: none;
	z-index: 999;
	position: fixed;
	top: 0;
	left: 0;
	@include fillSpace;
	@include clearfix;
	height: 100%;
	padding-bottom: 80px;
	.closeFaqBtn {
		@include absolute;
		background: $primary;
		font-size: 1.5em;
		padding: 20px 20px 13px;
		text-align: center;
		border-right: 2px solid $light;
		color: $light;
		cursor: pointer;
		@include transition;
		&:hover {
			background: $secondary;
		}
	}
	.accordionButtonWrapper:first-child {
		margin-top: 0px !important;
	}
}
#faqContent {
	width: 100%;
	min-height: 100%;
	margin: 0 auto;
	position: fixed;
	padding: 0 0 95px;
	color: $basic;
	top: -120%;
	text-align: center;
	@include transition($speed: 1s);
	//border-top: 45px solid $basic;
	&.results {
		.questCol {
			width: 40%;
			height: calc(100% - 60px);
			position: fixed;
		}
		.resultsCol {
			right: 0;
		}
	}
	&.showing {
		top: 0;
	}
	&.noFooter {
		.questCol {
			max-height: calc(100% - 65px)!important;
		}
		.resultsCol {
			height: calc(100% - 65px)!important;
		}
	}
	.questCol {
		padding: 50px;
		background: $light;
		position: absolute;
		width: 100%;
		max-height: calc(100% - 128px);
		overflow-y: auto;
		@include transition(.5s);
		.error {
			background: $wrong;
			color: $light;
			padding: 5px 15px;
			display: block;
			max-width: 800px;
			margin: 0 auto;
		}
		#question {
			display: block;
			padding: 6px 10px;
			width: 100%;
			min-height: 75px;
			max-width: 800px;
			margin: 20px auto 0;
		}
		.btn {
			@include btnStyle;
			display: block;
			width: 120px;
			margin: 20px auto;
			background: $primary!important;
			border: 1px solid $light;
			@include boxShadow($x: 0px, $y: 0px, $blur: 2px, $spread: 2px, $color: rgba(0,0,0,.2));
			&.viewAll {
				width: 180px;
			}
		}
	}
	.viewAll {
		border-top: 1px solid darken($grey,20%);
		max-width: 800px;
		padding: 30px 20px 20px;
		margin: 30px auto 0;
	}
	.resultsCol {
		width: 60%;
		height: calc(100% - 128px);
		background: $light;
		padding: 50px 50px 0;
		position: fixed;
		right: -60%;
		overflow-y: auto;
		@include transition(.5s);
		.message {
			background: $secondary;
			color: $light;
			padding: 10px 15px;
		}
	}
	.questNotAnswered {
		width: 100%;
		height: auto;
		background: $primary;
		padding: 10px 20px;
		position: fixed;
		right: 0;
		bottom: -200px;
		overflow-y: auto;
		border-top: 2px solid $light;
		@include boxShadow($x: 0px, $y: 0px, $blur: 2px, $spread: 2px, $color: rgba(0,0,0,.2));
		@include transition(.5s);
		&.show {
			bottom: 0;
		}
		h3 {
			color: $light;
		}
		.btn {
			@include btnStyle;
			display: inline-block;
			width: auto;
			margin: 0 15px;
			background: $primary!important;
			border: 1px solid $light;
			width: 180px;
			display: inline-block;
			margin: 0 0 0 10px;
			cursor: pointer;
			font-size: 0.85em;
		}
	}
	.submitQuestion {
		@include btnStyle;
		font-size: 0.8em;
		padding: 10px;
		display: inline-block;
		width: auto;
		margin: 0 15px;
		background: $primary!important;
		border: 1px solid $light;
	}
	.questNotAnsweredLightBox {
		font-family: $secondaryFont;
		display: none;
		@include fixed;
		@include fillSpace;
		@include transparentBG($basic,.85);
		z-index: 1;
		.questNotAnsweredLightBoxWrap {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 400px;
			margin: -200px 0 0 -200px;
			color: $light;
			h3 {
				font-family: $secondaryFont;
			}
			.questionToBeAnswered {
				display: block;
				margin: 15px 0;
				font-size: 1.25em;
			}
			.submitQuestion {
				margin: 15px auto;
				display: block;
				font-size: 1em;
			}
			.cancel {
				@include btnStyle;
				display: block;
				width: auto;
				margin: 25px auto;
				background: $secondary!important;
				color: $light;
				border: 1px solid $light;
				width: 100px;
				display: block;
				cursor: pointer;
				font-size: 0.8em;
				&:hover {
					color: $basic;
				}
			}
		}
	}
	.questThanks {
		@include fixed;
		@include fillSpace;
		@include transparentBG($basic,.85);
		z-index: 2;
		span {
			width: 400px;
			padding: 20px;
			color: $light;
			display: block;
			margin: 100px auto 0;
			font-size: 1.5em;
		}
		.questThanksWrap {
			position: absolute;
			top: 40%;
			left: 50%;
			margin: -200px 0 0 -200px;
		}
		.thanksCloseBtn {
			@include btnStyle;
			display: inline-block;
			width: auto;
			margin: 0 15px;
			background: $primary!important;
			border: 1px solid $light;
			width: 100px;
			display: block;
			margin: 0 auto;
			cursor: pointer;
		}
	}
	.titleWrap {
		background: $primary;
		padding: 15px 0;
		text-align: center;
	}
	h1 {
		text-transform: uppercase;
		color: $light;
		font-family: $primaryFont;
		font-weight: 400;
		font-size: 1.7em;
		//text-align:left;
		//padding-left:80px;
	}
}
@media (max-width:$sm) {
	#faqContentWrap {
		position: absolute;
	}
	#faqContent {
		position: relative;
		.titleWrap {
			padding: 15px 0 15px 77px;
			text-align: left;
		}
		.questCol,
		.resultsCol {
			position: relative!important;
			top: auto!important;
			bottom: auto!important;
			left: auto!important;
			right: auto!important;
			width: 100%!important;
			height: auto!important;
			overflow-y: visible;
			padding: 25px 25px 100px !important;
		}
		.accordionButton {
			padding-right: 20px !important;
		}
		.questNotAnsweredLightBox {
			.questNotAnsweredLightBoxWrap {
				top: 50px;
				left: 0;
				width: 100%;
				margin: 0;
				padding: 20px;
			}
		}
	}
}
