@-ms-viewport{ width: auto !important; }

html { //Shows sidebar at all times
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    overflow-x: hidden;
}

body {
	@include zeroMargPad;
	@include bodyCopy;
    //font-size: 14px;
    background: $light;
}
body:before{
    content:""!important;
}

#wrapper{
   // z-index: 1;
    position: relative;
}

#mainContent{
    position: relative;
    height: auto;
    padding: 0;
}
img{border:0;}

a {color: $secondary;}

h1, h2, h3, h4, h5, h6 {font-family: $primaryFont; margin: 0px;}

p{margin: 0px 0px 10px;}


//*****UNIVERSAL*****//

.container-fluid{
	padding:0;
	@media all and (max-width: 1370px){

	}
	@media all and (max-width: $sm){

	}
	@media all and (max-width: $xs){

	}
}

.row{
	margin:0!important;
}
#backgroundCover{
	width:100%;
	height:100%;
	@include fixed;
    //overflow:visible;
    background-image:url(/images/background.jpg);
    @include backgroundCover;
}

@media (max-width: $sm){
    .adaLink{
        width: 100%;
        height: 25px;
        font-size: 1em;
        text-align: center;
        padding: 2px 0px;
    }
}
