.slide.quizSlide{
    .videoWrapper{
        //margin:0;
    }



.quizCopy {

}

.quizButtons {
    @include zeroMargPad;
    list-style:none;
    margin-top: 15px;
    font-family: $primaryFont;
    border-left: 0px ;
    border-top: 0px;
  padding: 0px 15px;
    p {
        padding: 15px 10px 15px 5px;
        margin: 0px;
        font-size: 1.05em;
        line-height: 1.25em;
        position: relative;
        z-index: 2;
      //  @include transition($property: padding);
        display: table-cell !important;
    }
    li{
        background-image: none;
        padding:0px;
        cursor: pointer;
      //  color: $light;
        color: $light;
        margin-bottom: 5px;
        width: 100%;
        //  @include transition(margin,.2s);
        display:table;
        font-family: $primaryFont;
        font-weight: 400;
        font-size: 1.1em;
        line-height: 1.35em;
        overflow: hidden;
      //  border: 1px solid $primary;
          background: $grey;
        &:before{
            display: none;
        }
        html:not(.mobile) &{
          &:hover{
            &:after{
              width: 100%;
            }
          }
        }
        &:after{
          @include pseudoContent;
          width: 0px;
          height: 100%;
          right: 0px;
          top: 0px;
          background: $hover;
          position: absolute;
          z-index: 1;
          @include transition(.3s);
       }
        br{
            display:none;
        }
        span.letter{
            font-size: 1.6em;
            font-weight: 700;
            display: block;
            width: 50px;
            text-align: center;
            z-index: 2;
          //  color: $light;
          //  background: $primary;
            height: 100%;
            display:table-cell;
            vertical-align:middle;
            position: relative;


        }
    }

    .picked{
        background-color: $wrong;
        font-weight: bold;
        color: $light;
        html:not(.mobile) &{
          &:hover{
              background-color: $wrong;
              color: $light;
          }
        }
        span.letter {
            text-indent: -9999px;
            position: relative;
            &:before{
                text-indent: 0px;
                @include absolute;
                top:50%;
                margin-top:-10px;
                content: 'b';
                @include iconFont;
                color: $light;
                font-size: .8em;
                width: 100%;
                height: 100%;
                text-indent: 0px;
            }

        }
    }
    li.correct.picked {
        background-color: $correct;
        color: $light;
        html:not(.mobile) &{
          &:hover{
          background-color: $correct;
          color: $light;
          }
        }
        span.letter {
            text-indent: -9999px;
            position: relative;
          //  background: $correct;
            &:before{
                text-indent: 0px;
                @include absolute;
                top:50%;
                margin-top:-13px;
                content: 'a';
                @include iconFont;
                color: $light;
                font-size: 1em;
                width: 100%;
                height: 100%;
                text-indent: 0px;
            }
        }


    }
 }

}
.quizContent{
    margin-bottom: 20px;
    width: 100%;

    p{

        span{
            padding-right: 6px;
        }
    }
}
 p.answerCorrect,  p.answerWrong{display: none;}
 p.answerCorrect,  p.answerWrong, p.question{
    padding:5px 0px;
    color:$light;
    font-family: $basicFont;
     font-weight: 700;
     margin-bottom: 0px !important;
}
p.question {
    color: $basic;
    font-size: 1.2em;
    line-height: 1.55em;
    font-weight: 400;
    padding: 15px !important;
}
p.answerCorrect{color: $correct; font-size:1.1em; line-height:1.25em;	padding: 5px 15px !important;}
p.answerWrong{color: $wrong; font-size:1.1em; line-height:1.25em;	padding: 5px 15px !important;	}


@media (max-width: $md){
    p.question{
      padding: 0px !important;
      font-size: 1.1em;
      line-height: 1.4em;
    }
    p.answerWrong{
      font-size: .9em;
      padding: 5px 0px !important;
    }
    p.answerCorrect{
      font-size: .9em;
      padding: 5px 0px !important;
    }
    .slide.quizSlide {
      .quizButtons{
        padding: 0px;
        li{
          span.letter{
            font-size: 1.4em;
            width: 40px;
          }
        }
        p{
          //font-size: 1.1em;
          padding: 10px 10px 10px 5px;
        }
      }
    }
}

@media (max-width: $sm){
  .slide.quizSlide{
		.copyArea{
			width: 100%;
			margin-left: 0px;
		}
    .slideSide{
      width: 100%;
    }
    p.question{
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
	}
}

@media (max-width: $xs){
  .slide.quizSlide{
    p.question{
      font-size: 1.25em;
      line-height: 1.35em;
    }
    .quizButtons{
      margin-top: 5px;
    }
  }
}
