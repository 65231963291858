.adaLink{
  z-index: 350;
  position: fixed;
  right: 0px;
  bottom: 10px;
  font-size: .9em;
  // border-left: 1px solid $primary;
  // border-right: 1px solid $primary;
  //height: 40px;
  //width: 40px;
  color:$primary;
  //@include opacity(80,.8);
  text-transform: uppercase;
  font-family: $primaryFont;
  font-weight: 700;
  text-align: center;
  padding: 10px 8px;
  outline: none;
	@include transition;
  text-decoration: none;

	@media all and (max-width: $sm){
    top: auto;
    bottom: -100px;
	}
	&:hover,&:focus{
		background:$secondary;
    color:$light;
		text-decoration: none;
    outline: none;
    font-size: 1.1em;
    //font-size: 1em;
  //  top: 0px;
  //  @include opacity(100,1 );
	}
    .inSection &, .alertMode &, .searchMode &, .utilityOpen &{
      display: none;
    }
}
