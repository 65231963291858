/**MIXINS**/
@mixin header{
    color: $secondary;
    font-family: $primaryFont;

}

@mixin titleFont {
    @include header;
    font-weight: 400;
}

@mixin mainHeadFont {
    @include header;
    font-weight: 700;
}

@mixin bodyCopy {
    color: $basic;
    font-family: $basicFont;
}


@mixin maxWidth{
    max-width: $maxWidth;
    margin: 0 auto;
    padding: 0px;
    @include borderBox;
    @include clearfix;
}
@mixin innerMaxWidth{
    max-width: $maxWidth - 30;
    margin: 0 auto;
    padding: 0px 15px;
}

@mixin btnStyle {
        //background: $secondary;
        background: $link;
        text-decoration: none;
        font-size: .9em;
        font-family: $primaryFont;
        font-weight:700;
        position: relative;
        display: inline-block;
        padding: 10px 20px;
        color: $light;
        text-transform: uppercase;
        @include rounded(5px);
    &:hover{
        background: $hover;
        color: $light;
    }
}

@mixin button($color:$link){
	background:$color;
	text-decoration: none;
    font-size: .9em;
    font-family: $primaryFont;
    font-weight:400;
    position: relative;
    display: inline-block;
    padding: 5px 15px;
    color: $light;
    text-transform: uppercase;
    @include rounded(5px);
	&:hover{
		//background:darken($color,10%);
        background: $hover;
		text-decoration:none!important;
	}
}

@mixin inputStyle{
    padding-left: 8px;
  border: 0px;
  background-color: transparent;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  color: #666;
  font-size: 12px;
  font-weight: normal;
  float: left;
  height: 26px;
  outline: none;
}

@mixin btnBorder{
    border: 7px solid $light;
}

@mixin maxWidth {
    max-width: $maxWidth;
    //width: 100%;
    margin: 0 auto;
}

@mixin rspMargins{
    @media all and (max-width: $xl + 80){
		margin: 25px 85px 0 85px!important;
	}
	@media all and (max-width: $lg){

	}
	@media all and (max-width: $md){
		margin: 0 85px!important;
	}
	@media all and (max-width: $sm){
        margin:25px 45px 0 45px!important;
    }
    @media all and (max-width: $xs){
		margin:0!important;
    }
}

@mixin break{
/* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin footerHeight{
  height: 100px !important;
  @media (max-width: $lg){
    height: 70px !important;
  }
  @media (max-width: $sm){
    height: 60px !important;
  }
}

@mixin searchBarW{
  width: 460px;
  @media (max-width: $lg){
    width: 400px;
  }
  @media (max-width: $md){
    width: 100%;
  }
}

@mixin searchAreaW{
  width: calc(100% - 460px);
  @media (max-width: $lg){
    width: calc(100% - 400px);
  }
  @media (max-width: $md){
    width: 100%;
  }
}
//@mixin iconFont{
//    font-family: $iconFont !important;
//    font-style: normal !important;
//    font-weight: normal !important;
//    font-variant: normal !important;
//    text-transform: none !important;
//    speak: none;
//    line-height: 1;
//    -webkit-font-smoothing: antialiased;
//}

//@mixin olStyle{
//    margin-top: 5px;
//	list-style-type: none;
//	counter-reset:li; /* Initiate a counter */
//    margin-left:0; /* Remove the default left margin */
//    padding-left:0; /* Remove the default left padding */
//    li {
//        position:relative; /* Create a positioning context */
//        margin-left:2em; /* Give each list item a left margin to make room for the numbers */
//        list-style:none; /* Disable the normal item numbering */
//
//        &:before {
//            content:counter(li); /* Use the counter as content */
//            counter-increment:li; /* Increment the counter by 1 */
//            /* Position and style the number */
//            position:absolute;
//            top:3px;
//            left:-2em;
//           @include borderBox;
//            width:1.8em;
//            /* Some space between the number and the content in browsers that support
//               generated content but not positioning it (Camino 2 is one example) */
//            margin-right:8px;
//            padding:2px;
//            color:$light;
//            background:$secondary;
//            font-weight:bold;
//            text-align:center;
//            font-size: .9em;
//            @include rounded(50%);
//        }
//    }
//}
//
//@mixin ulStyle {
//        list-style-type: none;
//        @include zeroMargPad;
//        margin-left: 15px;
//        margin-bottom: 5px;
//        li {
//            @include zeroMargPad;
//            padding-left: 15px;
//            margin-bottom: 5px;
//            position: relative;
//
//            &:before{
//                @include iconFont;
//                @include absolute;
//                top: 6px;
//                color: $secondary;
//                @include pseudoContent;
//                content: 'f';
//                font-size: .75em;
//            }
//        }
//
//}

@mixin reformatList{
    list-style-type: none;
    @include zeroMargPad;
    @include clearfix;
}

@mixin darkBG{
     @include transparentBG($basic, .75);
}

@mixin imgResponsive{
    width: 100%;
}


/***ADA MIXINS***/
@mixin adaHeaderFont {
    color: $primary;
    font-family: $primaryFont;
}
@mixin adaBtns {
  display: inline-block;
  background: $primary;
  border: 2px solid $primary;
  font-family: $primaryFont;
  font-weight: 700;
  letter-spacing: .05em;
  text-transform: uppercase;
  font-size: 1em;
  color:$light;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  @include rounded(7px);
  &:hover, &:focus{
    color:$light;
    background: $secondary;
  }
}
@mixin adaBtnHover {
	text-decoration: underline;
}
@mixin adaSchoolLogo {
     padding: 20px 25px;
    font-family:$primaryFont;
    padding: 8px 0px 5px 97px;
	float:left;
	margin:8px 0 0 0;
	padding:0;


	//background:url(../images/uvu-logo.svg) no-repeat;
}

@mixin adaIconFont{
    font-family: 'ada' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}
