.slide {
  p {
    @include zeroMargPad;
    margin-bottom: 15px;
  }
  .intro {
    font-family: $primaryFont;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.45em;
    line-height: 1.7em;
  }
  .sub-head {
    font-family: $primaryFont;
    font-weight: 400;
    font-size: 1.45em;
    line-height: 1.7em;
  }
  ul {
    @include zeroMargPad;
    @include reformatList;
    border-top: 1px solid $primary;
    padding-top: 10px;
    padding-left: 25px;
    margin-bottom: 20px;
    li {
      padding-left: 15px;
      position: relative;
      &:before {
        @include pseudoContent;
        @include leftArrow($size: 8px, $sizeTB: 10px, $color: $primary, $top: 6px, $left: 0);
        border-bottom-width: 0;
      }
      ul{
        border-top: 0px;
        padding-left: 15px;
        padding-top: 0px;
        margin-bottom: 0px;
        li{
          &:before{
            border-right-color: $alt2;
          }
        }
      }
    }

  }
  h2 {
    margin-bottom: 18px;
    @include mainHeadFont;
    font-weight: 400;
    color: $primary;
    font-size: 1.7em;
  }
  h3 {
    margin-bottom: 7px;
    margin-top: 10px;
    @include mainHeadFont;
    line-height: 1.25em;
  }
  .listHeading {
    color: $secondary;
    font-weight: 700;
    margin-bottom: -10px;
    display: block;
  }
  .highlight {
    background: $alt;
    padding: 15px;
    display: block;
  }
  .blockText {
    background: $secondary;
    display: block;
    padding: 15px 20px;
    color: $light;
    line-height: 1.6em;
    font-style: italic;
    margin-top: 45px;
  }
  .PullQuote{
      font-size: 1.7em;
    color: $dark;
    font-weight: 400;
    padding-left: 50px;
    font-style: italic;
    display: block;
    line-height: 1.8em;
    position: relative;
    padding-right: 20px;
    margin-top: 20px;
      &:before{
        @include iconFont;
        color:$alt;
        content: '5';
        font-size: 1.9em;
        position: absolute;
        left: 10px;
        top: -15px;
        z-index: -1;
      }
      &:after{
        @include iconFont;
        content: '6';
        font-size: 1.9em;
        position: absolute;
        color:$alt;
        //transform: scale(-1, -1);
      }
    }
  .IntroText{
    font-size: 1.25em;
line-height: 1.55em;
font-weight: 400;
color:darken($dark, 5%);
  }
  .LinkButton{
    display: inline-block;
    background: $link;
    color:$light;
    font-weight: 700;
    padding: 10px 15px;
    font-size: 1.3em;
    a{
      display: inline-block;
      color:$light;
    }
  }
}

@media (max-width: $xl){
  .slide{
    .PullQuote{
      padding-right: 0px;
      font-size: 1.6em;
      padding-left: 0px;
      line-height: 1.65em;
      &:before{
        left: -40px;
        top: -20px;
      }
    }
  }
}

@media (max-width: $md + 100){
  .slide{
    .PullQuote{
      padding-right: 0px;
      font-size: 1.5em;
      padding-left: 10px;
      line-height: 1.65em;
      &:before{
        left: -25px;
        top: -20px;
      }
    }
  }
}


@media (max-width: $md){
  .slide{
    .PullQuote{
      padding-left: 10px;
      font-size: 1.4em;
      &:after{
        font-size: 1.5em;
      }
      &:before{
        font-size: 1.5em;
        left: -20px;
        top: -10px;
      }
    }

  }
}

@media (max-width: $xs){
  .slide{
    .IntroText{
      font-size: 1.15em;
      line-height: 1.45em;
    }
  }
}

@media (max-width: $xxs){
  .slide{
    ul{
      padding-left: 15px;
    }
  }
}
