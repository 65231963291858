#umbracoPreviewBadge {
  position: fixed !important;
  top: 0px !important;
  left: auto !important;
  width: 100% !important;
  height: auto !important;
  background: #bacb0f !important;
  display: block !important;
  padding: 0 4px 10px 4px;
  color: black;
  text-align: center;
  max-height: 80px;
  overflow: auto;
  //    &:before{
  //        display:inline-block;
  //        font-size:1.125em;
  //        font-weight:bold;
  //        text-transform:uppercase;
  //        content:"PREVIEW MODE";
  //        background:black;
  //        color:#bacb0f;
  //        padding:8px 15px;
  //    }
  &:after {
    display: block;
    font-size: 1em;
    margin-top: 5px;
    content: "PREVIEW MODE: This is a preview, and should be used for content evaluation only. Some features and styles may not act/look exactly the way they do on live site.";
  }
  &:hover {
    background: lighten(#bacb0f, 10%) !important;
  }
  //    &:first-of-type{
  //        display:none!important;
  //    }
  /* span{
        display:block!important;
    } */
}
$top: 35px;
.PreviewMode {
  #wrapper {
    top: $top;
  }
  header {
    top: $top;
  }

  .progressWrap {
    span {
      //            left: 65px;
      //            width: 20%;
      //            overflow: hidden;
    }
  }

  //    .timer{display: none;}
  #learnMoreWrapper {
    display: none !important;
  }

  .slideBody {
    margin-top: $top + 75px;
    position: relative;
    display: none !important;
    .timer {
      background: $secondary;
    }
  }

  .alertBar {
    display: none !important;
  }
  .adaBtn {
    display: none !important;
  }
  .menuBtn {
    display: none;
  }

  #backgroundImg {
    display: none;
  }
}

$smPrev: 38px;

@media (max-width: $width-sm) {
  #umbracoPreviewBadge {
    padding: 0 4px 0px 4px;
    &:after {
      display: none !important;
    }
  }

  .PreviewMode {
    //        .progressWrap{
    //            margin-top: 38px;
    //            span{
    //                left: 0px !important;
    //            }
    //        }
    //        .mainBar{
    //            position: fixed;
    //            top: 0px !important;
    //        }
    header {
      top: $smPrev;
    }
    .slideBody {
      margin-top: $smPrev + 74px;
    }
  }
  .arrowNav {
    top: $smPrev;
  }
}
@media (max-width: $width-xsm) {
  .PreviewMode {
    .slideBody {
      margin-top: 100px;
    }
  }
}
