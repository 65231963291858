@keyframes menuBtnsOddIn {
    0%   {top: -50px; left: -50px;}
    100%  {top: 0px; }
}
@keyframes menuBtnsEvenIn {
    0%   {bottom: -50px; right: -50px;}
    100%  {bottom: 0px; right: 0px;}
}

@keyframes showCorner {
  0%   {top: -400px;}
  70% {top: -400px;}
  100%  {top: -525px;}
}

@keyframes btnTitle {
  0%   {bottom: -300px;}
  50% {bottom: -300px;}
  85% {bottom: 20px;}
  100%  {bottom: 0px;}
}


@keyframes headerIn {
  0%   {top: -200px;}
  100%  {top: 0px;}
}
@keyframes footerIn {
  0%   {bottom: -200px;}
  100%  {bottom: 0px;}
}
@keyframes alertsIn {
  0%   {top: -200px;}
  100%  {top: 00px;}
}

@keyframes alertsInRsp {
  0%   {bottom: -200px;}
  100%  {bottom: 15px;}
}


/* slides */
@keyframes emphasizeIcon {
  0%   {font-size: 3.5em;  color: $secondary;}
  50%   {font-size: 5em;}
  100%  {font-size: 3.5em;  color: $light;}
}
